/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import BarcodeReader from 'react-barcode-reader';
import DatePicker from "react-datepicker";
import moment from 'moment';
import AsyncCreatableSelect from "react-select/async-creatable";
import * as Yup from 'yup';
import uniqid from 'uniqid';
import { Formik, Field } from 'formik';
import { sortBy, isEmpty } from 'lodash';
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import swal from 'sweetalert';

import Spinner from '../../components/spinner';
import Header from '../../components/pageHeader';
import { phoneNumberMask, updateToken } from '../../helpers/utils';
import { getDistance, getServiceTypeById, getDefaultServiceType, getShippersById, getDeliveryTimeSlotById, addEditShipper, getDefaultDeliveryTimeSlot, getDefaultStorageType, getDefaultProductType, addAddressById, getDefaultStorageById, getProductTypeById, getAddressById, getOrderNumberSettings, getOrderNumberSettingsById, getShipmentFormData } from '../../services/index';
import axios from 'axios';
const AddShipments = (props) => {
  const parcelData = {
    product_type: '',
  };

  const parcelSchema = Yup.object().shape({
    product_type: Yup.string().required('Product Type is required.'),
  });

  const [addressData, setAddressData] = useState({
    name: '',
    secondary_name: '',
    company: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    location: {},
    save_address: false,
  });

  const addressSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required.')
      .matches(/(\d*[a-zA-Z]+\d*)+/, 'Name cannot contain only numbers'),
    secondary_name: Yup.string().matches(/(\d*[a-zA-Z]+\d*)+/, 'Name cannot contain only numbers'),
    address1: Yup.string().required('Address 1 is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    postal_code: Yup.string().transform((value) => value && value.replace(/[\\/]/g, '')).required('Postal Code is required.'),
    phone: Yup.string()
      .transform((value) => value && value.replace(/\D/g, ''))
      .required('Please enter a valid phone number.'),
  });
  const shipperList = props.shippers;
  const shipperName = props.shipper.name;
  const [formData, setFormData] = useState({
    order_number: '',
    contact_name: '',
    secondary_contact_name: '',
    company_name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    location: {},
    save_address: false,
    alias: '',
    group_name: '',
    parcels: '1',
    delivery_timeslot: 'Anytime',
    delivery_instructions: '',
    adult_signature: false,
    refrigeration: false,
    order_code: '',
    selectedProductType: '',
    carrier_alias: shipperName === 'MetroHealth Mail Order Pharmacy' || shipperName === 'MetroHealth Specialty Pharmacy' ? 'clockwork' : '',
    service_delivery_date: '',
  });

  const [qrCodeData, setQRCodeData] = useState('');
  const [distance, setDistance] = useState({});
  const [loading, showSpinner] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [isLabOrder, setLabOrder] = useState(false);
  const [addressBook, setAddressBook] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [serviceType, setServiceType] = useState('');
  const [time, setTime] = useState('')
  const [storageType, setStorageType] = useState('');
  const [modalParcel, showParcelModal] = useState(false);
  const [modalAddress, showAddressModal] = useState(false);
  const [shipperDetails, setShipperDetails] = useState({});
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [deliveryTimeSlot, setDeliveryTimeSlot] = useState([]);
  const [storageTypesList, setStorageTypesList] = useState([]);
  const [defaultProductType, setDefaultProductType] = useState('');
  const [modalConfirmOrder, showConfirmOrderModal] = useState(false);
  const [carrierName, setCarrierName] = useState('Clockwork Logistics');
  const [isDataValid, setDataValid] = useState({
    postal_code: '',
    contact_name: '',
    secondary_contact_name: '',
  });
  const [orderList, setOrderList] = useState([{ order_number: '', product_type: '' }]);
  const [isOrderNumberRequired, setOrderNumberRequired] = useState(true);
  const [serviceDeliveryStartDate, setServiceDeliveryStartDate] = useState(new Date());
  const [isServiceDeliveryStartDateRequired, setServiceDeliveryStartDateRequired] = useState(true);
  const [state, setState] = useState([])

  useEffect(() => {
    getShipperDetails();
    getAddress();
    getProductsType();
    getStorageType();
    getServiceType();
  }, [props.shipper.id]);

  useEffect(() => {
    reCalculateTimeSlot();
  }, [props.shipper.id, props.shipper.name, serviceType]);

  useEffect(() => {
    if (qrCodeData.length > 0) {
      const data = qrCodeData.split('^');
      setFormData({ ...formData, order_number: data[0] });
      calculateMiles(data);
    }
  }, [qrCodeData]);

  useEffect(() => {
    getFedExService();
  }, [carrierName])

  const calculateMiles = async (data) => {
    const address = `${data[4]},${data[5]}, ${data[6]}, ${data[7]}`;
    const selectedAddress = await geocodeByAddress(address);
    const LatLong = await getLatLng(selectedAddress[0]);
    let location = {};
    if (LatLong) {
      location._latitude = LatLong.lat;
      location._longitude = LatLong.lng;
      const shipToLocation = {
        latitude: LatLong.lat,
        longitude: LatLong.lng,
      };
      await getShipmentDistance(shipToLocation);
    }
    if (shipperDetails?.group_name.toLowerCase() === 'ucsd' && data[8]?.length > 0) {
      let scanParcelData = data[8]
      let splited_orderNumber = scanParcelData.split(',')
      const updatedParcelCount = [];
      splited_orderNumber.map((item) => {
        return updatedParcelCount.push({ order_number: item || '' })
      })
      setOrderList(updatedParcelCount)
    }

    setAddressData({
      name: data[1],
      email: data[3],
      phone: data[2],
      address1: data[4],
      address2: '',
      location: location,
      city: data[5],
      state: data[6],
      postal_code: data[7],
    });
    showAddressModal(true);
  };

  const getShipperDetails = async () => {
    try {
      const response = await getShippersById(props.shipper.id);

      if (response && (response.status === 200 || response.status === 201)) {
        setShipperDetails(response?.data);

        await getOrderNumberSetting(props.shipper.id);

        if (response?.data?.group_name?.toLowerCase() === 'lab') {
          setLabOrder(true);
          setFormData({
            ...formData,
            adult_signature: true,
          });
        } else if (response?.data?.group_name?.toLowerCase().includes('vmfh')) {
          getDeliveryTimeSlot();
          if (response?.data?.alias.toLowerCase().includes('vmfh')) {
            setFormData({
              ...formData,
              adult_signature: true,
            });
          } else {
            setFormData({
              ...formData,
              adult_signature: false,
            });
          }

        } else {
          setLabOrder(false);
          getDeliveryTimeSlot();
          setFormData({
            ...formData,
            adult_signature: false,
          });
        }

        if (response?.data?.group_name?.toLowerCase() === 'trinity' || response?.data?.alias?.toLowerCase() === 'ucsd mos pharmacy') {
          setFormData({ ...formData, adult_signature: true });
        }

        if (!isEmpty(formData?.location)) {
          await setDistance({});
          const shipToLocation = {
            latitude: formData?.location?._latitude,
            longitude: formData?.location?._longitude,
          };
          await getShipmentDistance(shipToLocation);
        }
      } else {
        if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
          await updateToken();
        }
        if (response?.response?.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getOrderNumberSetting = async (shipperId) => {
    try {
      const response = await getOrderNumberSettingsById(shipperId);

      if ((response.status === 200 || response.status === 201) && response?.data.length) {
        setOrderNumberRequired(response?.data[0]?.value === `true` ? true : false);
      } else {
        const response = await getOrderNumberSettings();

        if ((response.status === 200 || response.status === 201) && response?.data) {
          setOrderNumberRequired(response?.data[0]?.value === `true` ? true : false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getDeliveryTimeSlot = async () => {
    try {
      const response = await getDeliveryTimeSlotById(props.shipper.id);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          const TimeSlot = response?.data?.map((item) => {
            const start_range = item.start_time.split(':');
            item.time = Number(start_range[0]);
            return item;
          });
          setDeliveryTimeSlot(TimeSlot);
        } else {
          const defaultTimeSlot = await getDefaultDeliveryTimeSlot();
          const TimeSlot = defaultTimeSlot?.data?.map((item) => {
            const start_range = item.start_time.split(':');
            item.time = start_range[0];
            return item;
          });
          setDeliveryTimeSlot(TimeSlot);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(`Failed to fetch delivery time slot`);
    }
  };

  const getAddress = async () => {
    showSpinner(true);
    try {
      const response = await getAddressById(props.shipper.id);
      if (response && (response.status === 200 || response.status === 201)) {
        let addressList = response?.data?.map((item) => {
          item.color = 'transparent';
          item.savedAddress = true;
          return item;
        });
        addressList.push({
          id: uniqid(),
          shipper_id: props.shipper.id,
          color: 'transparent',
          savedAddress: false,
        });
        setAddressBook(addressList);
        showSpinner(false);
      } else {
        showSpinner(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Failed to fetch shipper details`);
    }
  };

  const getShipmentDistance = async (shipToLocation) => {
    if (!isEmpty(shipToLocation)) {
      const request = {
        ship_from: {
          location: {
            _latitude: shipperDetails?.address?.location?._latitude,
            _longitude: shipperDetails?.address?.location?._longitude,
          },
          shipper_id: props?.shipper?.id,
        },
        ship_to: {
          location: {
            _latitude: shipToLocation?.latitude,
            _longitude: shipToLocation?.longitude,
          },
        },
      };
      try {
        const response = await getDistance(request);
        if (response && (response.status === 200 || response.status === 201)) {
          await setDistance({ distance, ...response?.data });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getProductsType = async () => {
    showSpinner(true);
    try {
      const response = await getProductTypeById(props.shipper.id);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          setProductTypes(response?.data);
          setDefaultProductType(response?.data && response?.data[0].name);
          showSpinner(false);
        } else {
          const defaultProductTypes = await getDefaultProductType();
          setProductTypes(defaultProductTypes?.data);
          setDefaultProductType(defaultProductTypes?.data && defaultProductTypes?.data[0].name);
          showSpinner(false);
        }
      } else {
        showSpinner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStorageType = async () => {
    setStorageType('');
    showSpinner(true);
    try {
      const response = await getDefaultStorageById(props.shipper.id);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          const storageTypes = await sortBy(response?.data, 'sort_order');
          setStorageType(storageTypes && storageTypes[0]?.name);
          setStorageTypesList(storageTypes);
          showSpinner(false);
        } else {
          const defaultStorage = await getDefaultStorageType();
          const defaultStorageTypes = await sortBy(defaultStorage?.data, 'sort_order');
          setStorageType(defaultStorageTypes && defaultStorageTypes[0]?.name);
          setStorageTypesList(defaultStorageTypes);
          showSpinner(false);
        }
      } else {
        showSpinner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getServiceType = async () => {
    setServiceType('');
    showSpinner(true);

    try {
      const response = await getServiceTypeById(props.shipper.id);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          const serviceTypes = await sortBy(response?.data, 'sort_order');
          setServiceType(serviceTypes && serviceTypes[0]?.name);
          setServiceTypeList(serviceTypes);
          showSpinner(false);
        } else {
          const defaultServiceResponse = await getDefaultServiceType();
          const defaultServiceTypes = await sortBy(defaultServiceResponse?.data, 'sort_order');
          setServiceType(defaultServiceTypes && defaultServiceTypes[0]?.name);
          setServiceTypeList(defaultServiceTypes);
          showSpinner(false);
        }
      } else {
        showSpinner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fedExServiceType = [
    { name: 'GROUND_HOME_DELIVERY', value: '', serviceType: 'Ground Home Delivery' },
    { name: 'STANDARD_OVERNIGHT', value: '', serviceType: 'Standard Overnight' }
  ]


  const getFedExService = () => {

    if (shipperName === 'UCSD Specialty Pharmacy (Kearny Mesa)' && carrierName === 'FedEx') {
      setServiceType(fedExServiceType[0]?.name)
      setFormData({ ...formData, weight: 1 })
    } else setServiceType(serviceTypeList[0]?.name)
  }

  const Validity = () => {
    let validityMessage = '';

    if (!isEmpty(distance) && (carrierName !== 'FedEx' && distance?.shipment_miles <= 5000) && Number(distance?.shipment_miles) > Number(distance?.shipper_miles)) {
      validityMessage = `Order #(${formData.order_number}) address is more than ${distance?.shipper_miles} miles! Please choose a different address.`;
    }

    const selectedAddress = addressId !== null ? addressBook.find((item) => item.id === addressId && item?.create_date?.length > 0) || {} : {};
    setSelectedAddress(selectedAddress);
    const isAddressValid = !isEmpty(selectedAddress) || (formData.address1?.length > 0 && formData.contact_name?.length > 0 && formData.city?.length > 0 && formData.state?.length > 0 && formData.postal_code?.length > 0);
    if (!isAddressValid) {
      validityMessage = 'Please select the address or add a new delivery address';
    }

    const orderValidity = formData?.order_number?.length > 0;
    if (isOrderNumberRequired && !orderValidity) {
      validityMessage = 'Please enter the order number.';
    }
    if (isServiceDeliveryStartDateRequired && serviceType?.toLowerCase() === 'future') {
      validityMessage = 'Date is required for Future Service Type';
    }
    return validityMessage;
  };

  const confirmShipment = async () => {
    const validityMessage = await Validity();
    if (validityMessage?.length <= 0) {
      showConfirmOrderModal(true);
    } else {
      toast.error(validityMessage);
      showConfirmOrderModal(false);
    }
  };

  const onAddNewShipment = async () => {
    let parcelData = [];
    orderList.forEach((item, index) => {
      parcelData.push({
        description: `Item ${index}`,
        productType: item?.product_type?.length > 0 ? item.product_type : defaultProductType,
        reference_number: item?.order_number,
      });
    });
    const weightForFedex = (shipperName === 'UCSD Specialty Pharmacy (Kearny Mesa)' && carrierName === 'FedEx' && formData.weight) ? formData.weight : 1
    const updatedParcelData = [{ items: parcelData }];
    let delivery_instructions = formData.delivery_instructions;
    if ((storageType?.toLowerCase() === 'refridge' || storageType?.toLowerCase() === 'refrigeration')) {
      delivery_instructions = formData.delivery_instructions?.length > 0 ? `${delivery_instructions},  Refrigeration is required.` : ' Refrigeration is required.';
    }
    try {
      const request = {
        status: 'Ready for Pickup',
        distance: isEmpty(distance) ? '' : distance?.shipment_miles.toString(),
        special_instruction: formData.delivery_instructions,
        refrigeration_required: storageType?.length > 0 ? (storageType?.toLowerCase() === 'refridge' || storageType?.toLowerCase() === 'refrigeration' ? true : false) : false,
        signature_required: formData.adult_signature,
        order_code: formData.order_code,
        storage_type: storageType?.length > 0 ? storageType : 'Room Temp',
        order_number: formData.order_number?.trim()?.replace('/', '').replace('\\', ''),
        delivery_instructions,
        delivery_timeslot: isLabOrder ? '' : formData.delivery_timeslot,
        service_type: serviceType?.length > 0 ? serviceType : isLabOrder ? 'STAT (1hr)' : 'Same Day',
        parcels: updatedParcelData,
        service_delivery_date: new Date(formData?.service_delivery_date),
        carrier_alias: formData.carrier_alias,
        weight: weightForFedex || formData.weight,
        ship_to: {
          name: isEmpty(selectedAddress) ? formData.contact_name : selectedAddress.name,
          contact_name: isEmpty(selectedAddress) ? formData.contact_name : selectedAddress.name,
          company_name: formData?.company,
          secondary_contact_name: formData.secondary_name,
          phone: formData.phone,
          email: formData?.email.toLowerCase()?.trim(),
          address1: isEmpty(selectedAddress) ? formData?.address1?.trim() : selectedAddress?.address1?.trim(),
          address2: isEmpty(selectedAddress) ? formData?.address2?.trim() : selectedAddress?.address2?.trim(),
          location: isEmpty(selectedAddress) ? formData.location : selectedAddress.location,
          city: isEmpty(selectedAddress) ? formData.city?.trim() : selectedAddress.city?.trim(),
          state: isEmpty(selectedAddress) ? formData.state?.trim() : selectedAddress.state?.trim(),
          postal_code: isEmpty(selectedAddress) ? formData.postal_code : selectedAddress.postal_code,
        },
        ship_from: {
          shipper_name: props.shipper.name,
          shipper_id: props.shipper.id,
          user_name: props.user.name,
          email: props.user.email.toLowerCase()?.trim(),
          user_id: props.user.id,
          alias: shipperDetails.alias,
          phone: shipperDetails?.phone,
          group_name: shipperDetails.group_name,
          address1: shipperDetails?.address?.address1?.trim(),
          address2: shipperDetails?.address?.address2?.trim(),
          location: shipperDetails.address.location,
          city: shipperDetails?.address?.city?.trim(),
          state: shipperDetails?.address?.state?.trim(),
          postal_code: shipperDetails.address.postal_code,
          timezone: shipperDetails?.address?.timezone || {},
        },
      };
      showSpinner(true);
      const response = await addEditShipper('shipment', request);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          if (formData.save_address) {
            await SaveAddress();
          }

          if (request.ship_from.shipper_name.includes('MetroHealth')) {
            props.history.push(`/shipment/${response.data.id}/overview`, {
              print: true
            })
          } else if (shipperDetails.group_name.toLowerCase() === 'vmfh') {
            props.history.push(`/shipment/${response.data.id}/overview`, {
              print: true
            })
          } else if (shipperDetails.group_name.toLowerCase() === 'ucsd') {
            props.history.push(`/shipment/${response.data.id}/overview`, {
              print: true
            })
          } else if (request.ship_from.shipper_name === 'St. Elizabeth Healthcare Specialty Pharmacy' && carrierName === 'FedEx') {
            props.history.push(`/shipment/${response.data.id}/overview`, {
              print: true
            })
          } else { props.history.push('/shipments') }

          showConfirmOrderModal(false);
          const message = response?.data?.number ? `# ${response?.data?.number} for ${formData.contact_name} has been added to shipment` : `New Order has been added to shipment`;
          toast.success(message);
        } else {
          if (response?.response?.status === 409) {
            toast.error(response.response.data.error);
          }
          if (response?.response?.status === 400 && response?.response?.data.error_code === 'max_distance') {
            toast.error(response.response.data.error);
          }
          if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized' || response?.response?.status >= 500) {
            await updateToken();
          }
          showConfirmOrderModal(false);
        }
        showSpinner(false);
      }
      showSpinner(false);
    } catch (error) {
      console.log(error);
      showSpinner(false);
    }
  };

  const SaveAddress = async () => {
    const address = {
      name: isEmpty(selectedAddress) ? formData.contact_name : selectedAddress.name,
      shipper_id: props.shipper.id,
      address1: formData.address1,
      address2: formData.address2,
      location: formData.location,
      city: formData.city,
      state: formData.state,
      postal_code: formData.postal_code,
    };
    const response = await addAddressById(props.shipper.id, address);
    if (response && (response.status === 200 || response.status === 201)) {
    }
  };

  const handleAddressSelect = async (value, placeid) => {
    let selectedAddress = await geocodeByPlaceId(placeid)
    // const selectedAddress = await geocodeByAddress(value);
    const LatLong = await getLatLng(selectedAddress[0]);
    let street_number = '';
    let sub_locality1 = '';
    let sub_locality2 = '';
    let sub_locality3 = '';
    let sub_locality4 = '';
    let route = '';
    let city = '';
    let state = '';
    let postal_code = '';
    let location = {};
    if (LatLong) {
      location._latitude = LatLong.lat;
      location._longitude = LatLong.lng;
      const shipToLocation = {
        latitude: LatLong.lat,
        longitude: LatLong.lng,
      };
      await getShipmentDistance(shipToLocation);
    }
    !!selectedAddress[0].address_components?.length &&
      selectedAddress[0].address_components.forEach((address) => {
        if (address.types.includes('street_number')) street_number = address.short_name;
        if (address.types.includes('sublocality_level_3')) sub_locality1 = address.short_name;
        if (address.types.includes('sublocality_level_2')) sub_locality2 = address.short_name;
        if (address.types.includes('sublocality_level_1')) sub_locality3 = address.short_name;
        if (address.types.includes('country')) sub_locality4 = address.long_name;
        if (address.types.includes('route')) route = address.short_name;
        if (address.types.includes('locality')) city = address.short_name;
        if (address.types.includes('administrative_area_level_1')) state = address.short_name;
        if (address.types.includes('postal_code')) postal_code = address.long_name;
      });

    setAddressData({
      ...addressData,
      address1: street_number ? `${street_number} ${route}` : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route} ${sub_locality4}`,
      postal_code,
      city,
      state,
      location,
    });
  };

  const handleAddress1Change = (address1, values) => {
    setAddressData({
      ...values,
      address1: address1,
    });
  };

  const reCalculateTimeSlot = () => {
    setFormData({ ...formData, delivery_timeslot: 'Anytime' });
  };

  const switchShipper = ({ target: { value } }) => {
    const shipper = shipperList.find((item) => item.name?.toLowerCase() === value?.toLowerCase());
    if (shipper) {
      props.dispatch({ type: 'SHIPPER', payload: shipper });
      if (shipper.role === 'viewer') {
        toast.error(`You don't have access to create a shipment for ${shipper.name}. Please contact admin`);
        props.history.push('/shipments');
      }
    }
  };

  const onInputChange = async ({ target: { name, value, checked } }) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'adult_signature' || name === 'save_address') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    }
    if (name === 'postal_code' && value.length > 0) {
      if (!/^[0-9]*$/.test(formData?.postal_code)) {
        setDataValid({ ...isDataValid, postal_code: 'Please Enter the valid postal code' });
      } else {
        setDataValid({ ...isDataValid, postal_code: '' });
      }
    }
    if (name === 'contact_name' && value.length > 0) {
      if (!/(\d*[a-zA-Z]+\d*)+/.test(formData?.contact_name)) {
        setDataValid({ ...isDataValid, contact_name: 'Name cannot contain only numbers' });
      } else {
        setDataValid({ ...isDataValid, contact_name: '' });
      }
    }
    if (name === 'secondary_contact_name' && value.length > 0) {
      if (!/(\d*[a-zA-Z]+\d*)+/.test(formData?.secondary_contact_name)) {
        setDataValid({ ...isDataValid, secondary_contact_name: 'C/O cannot contain only numbers' });
      } else {
        setDataValid({ ...isDataValid, secondary_contact_name: '' });
      }
    }
  };

  const onHandleNavChange = async (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectAddress = (address) => {
    const updatedAddress = addressBook?.map((item) => {
      if (item.id === address?.id) {
        const shipToLocation = {
          latitude: item?.location?._latitude,
          longitude: item?.location?._longitude,
        };
        if (address.savedAddress) {
          getShipmentDistance(shipToLocation);
        }
        return { ...item, color: 'bg-success-soft' };
      }
      return { ...item, color: 'transparent' };
    });
    setAddressBook(updatedAddress);
    setAddressId(address?.id);
    if (!address.savedAddress) {
      showAddressModal(true);
    }
  };

  const handleAddParcel = (data) => {
    const updatedParcelCount = [...orderList];
    updatedParcelCount.push({
      product_type: data.product_type,
      order_number: data.order_number,
    });
    setOrderList(updatedParcelCount);
    showParcelModal(false);
  };

  const handleNewAddress = (data) => {
    setQRCodeData("")
    let updatedAddressList = [...addressBook];
    updatedAddressList = updatedAddressList?.map((item) => {
      return { ...item, color: 'transparent' };
    });
    const address = {
      id: uniqid(),
      shipper_id: props.shipper.id,
      name: data.name,
      secondary_name: data.secondary_name,
      company: data.company,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      postal_code: data.postal_code,
      location: data.location,
      color: 'bg-success-soft',
      savedAddress: true,
    };
    updatedAddressList.splice(updatedAddressList.length - 1, 0, address);
    setAddressBook(updatedAddressList);
    setFormData({
      ...formData,
      contact_name: data.name,
      secondary_name: data.secondary_name,
      company: data.company,
      email: data.email,
      phone: data.phone,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      postal_code: data.postal_code,
      location: data.location,
      save_address: data.save_address,
    });
    showAddressModal(false);
    setAddressData({
      name: '',
      secondary_name: '',
      company: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      location: {},
      save_address: false,
    });
  };

  const toggleAddressModal = (value) => {
    showAddressModal(value);
    let updatedAddressList = [...addressBook];
    updatedAddressList = updatedAddressList?.map((item) => {
      return { ...item, color: 'transparent' };
    });
    setAddressBook(updatedAddressList);
    setAddressData({
      name: '',
      secondary_name: '',
      company: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      location: {},
      save_address: false,
    });
    setDistance({});
    setAddressId(null);
  };

  const handleAddRecord = (e) => {
    e.preventDefault();
    const values = [...orderList];
    values.push({
      product_type: '',
      order_number: '',
    });
    setOrderList(values);
  };

  const handleChangeRecord = (index, event, name) => {
    const values = [...orderList];
    if (name === 'orderNumber') {
      values[index].order_number = event.target.value;
    } else if (name === 'productType') {
      values[index].product_type = event.target.value;
    }
    setOrderList(values);
  };

  const handleRemoveRecord = (index) => {
    if (index !== 0) {
      const values = [...orderList];
      values.splice(index, 1);
      setOrderList(values);
    } else {
      toast.error(`Atleast 1 Parcel is required`);
    }
  };

  const handleScan = (data) => {
    console.log('its paired');
    data !== qrCodeData && toggleAddressModal(false)
    setQRCodeData(data.replace(/[\\/]/g, ''));
  };

  const handleError = (err) => {
    console.error(err);
    window.location.reload();
  };

  const handleFutureDate = (value) => {
    setServiceDeliveryStartDate(value);
    const finalSelectedDate = moment(value).format('MMM DD, YYYY');

    setFormData({
      ...formData,
      service_delivery_date: finalSelectedDate,
    });
    setServiceDeliveryStartDateRequired(false);
  };

  const handleFutureDateReset = () => {
    setServiceDeliveryStartDate(new Date());
    setServiceDeliveryStartDateRequired(true);
    setFormData({
      ...formData,
      service_delivery_date: '',
    });
  };

  const modalAddressClose = () => {
    toggleAddressModal(false);
    setQRCodeData("")
  }

  const serviceDeliveryFutureRef = useRef(null)
  // const handleLabel = useCallback(e => e.nativeEvent.target !== serviceDeliveryFutureRef.current && e.preventDefault(), []);

  const items = async (keyword) => {
    let items = []
    let response = await getShipmentFormData(props?.shipper?.id, keyword);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        if (response.data.length >= 1) {
          setState(response.data)
          response.data.forEach((elem) => {
            let address = ''
            if (elem.address1) address = address.concat(elem.address1 + ', ')
            if (elem.address2) address = address.concat(elem.address2 + ', ')
            if (elem.city) address = address.concat(elem.city + ', ')
            if (elem.state) address = address.concat(elem.state + ' ')
            if (elem.postal_code) address = address.concat(elem.postal_code + ' ')
            items.push({
              value:
              {
                name: `${elem.contact_name}`,
                phone: `${elem?.phone}`,
                email: `${elem?.email}`,
                postal_code: `${elem?.postal_code}`,
              }, label: `${elem.contact_name} (${address})`
            })
          })
        }
        return items
      }
    }
  }

  const onChangeFunction = (e) => {
    const array = state.filter((elem) => {
      return (
        elem?.contact_name === e?.value?.name
        && elem?.phone === e?.value?.phone
        && elem?.email === e?.value?.email
        && elem?.postal_code === e?.value?.postal_code
      )
    })

    if (array.length !== 0) {
      array.forEach(async (elem) => {
        setAddressData({
          name: `${elem?.contact_name}`,
          secondary_name: `${elem?.secondary_contact_name}`,
          company: `${elem?.company_name}`,
          email: `${elem?.email}`,
          phone: `${elem?.phone}`,
          address1: `${elem?.address1}`,
          address2: `${elem?.address2}`,
          city: `${elem?.city}`,
          state: `${elem?.state}`,
          postal_code: `${elem?.postal_code}`,
          location: elem?.location,
          save_address: false,
        })
        if (elem.location) {
          const shipToLocation = {
            latitude: elem.location._latitude,
            longitude: elem.location._longitude,
          };
          await getShipmentDistance(shipToLocation)
        }
      })


    }
    else {
      setAddressData({
        name: `${e?.value}`,
        secondary_name: '',
        company: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postal_code: '',
        location: {},
        save_address: false,
      }
      )
    }
  }

  const deleteAddressBookData = (address) => {
    swal({
      text: `Are you sure you want to remove this address?`,
      buttons: ['No', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      showSpinner(false);
      if (status) {
        await axios.delete(`shipper/${props?.shipper?.id}/address/${address?.id}`)
        getAddress()
        toast.success('Address has been deleted')
        showSpinner(false);
      } else {
        showSpinner(false);
      }
    });
  }


  const SERVICE_TYPE = {
    'future': { 'value': formData?.service_delivery_date, 'serviceType': serviceType },
    'same day': { 'value': moment().format('MMM DD, YYYY'), 'serviceType': serviceType },
    'next day': { 'value': moment().add(1, 'days').format('MMM DD, YYYY'), 'serviceType': serviceType },
    'next monday': { 'value': moment().startOf('isoWeek').add(1, 'week').format('MMM DD, YYYY'), 'serviceType': serviceType },
  }

  return (
    <Spinner display={loading}>
      <Header title='' name='New Shipment' />
      <div className='container-fluid'>
        <form>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='form-group'>
                  <label className='mb-2'>
                    Pickup location <span className='text-danger'>*</span>
                  </label>
                  <select className='form-control' custom value={shipperName === "All Locations" ? null : shipperName} onChange={(event) => switchShipper(event)}>
                    <option value='' selected disabled hidden>
                      Choose here
                    </option>
                    {shipperList?.map((type, index) => {
                      return (
                        <option key={index} value={type.name}>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                  {shipperName === "All Locations" && <small className='text-danger'>Pickup Location is required</small>}
                </div>
              </div>
              <div className='row'>
                <div className='form-group'>
                  <label className='mb-2'>
                    Order No./Reference No.{isOrderNumberRequired && <span className='text-danger'>*</span>}
                  </label>
                  <input className='form-control' type='text' name='order_number' placeholder='e.g. AB12-TS322' value={formData?.order_number.replace(/[\\/]/g, '')} onChange={onInputChange} isInvalid={isOrderNumberRequired} />
                  {isOrderNumberRequired && formData?.order_number.length <= 0 && <small className='text-danger'>Order Number is required</small>}
                </div>
              </div>
              {

                (shipperName === 'MetroHealth Mail Order Pharmacy' || shipperName === 'MetroHealth Specialty Pharmacy' || shipperName === 'St. Elizabeth Healthcare Specialty Pharmacy' || shipperName === 'UCSD Specialty Pharmacy (Kearny Mesa)') &&
                <div className='row'>
                  <div className='form-group'>
                    <label className='mb-2'>
                      Carrier Service <span className='text-danger'>*</span>
                    </label>

                    <select className='form-control' defaultValue={shipperName === 'MetroHealth Specialty Pharmacy' ? 'clockwork' : shipperName === 'UCSD Specialty Pharmacy (Kearny Mesa)' ? 'Atlas Transport (San Diego)' : 'KTD Transport and Property Management, LLC'} onChange={(e) => { onInputChange(e); setCarrierName(e.target[e.target.selectedIndex].text) }} name='carrier_alias'>
                      <option value={shipperName === 'MetroHealth Specialty Pharmacy' || shipperName === 'MetroHealth Mail Order Pharmacy' ? 'clockwork' : shipperName === 'UCSD Specialty Pharmacy (Kearny Mesa)' ? 'Atlas Transport (San Diego)' : 'KTD Transport and Property Management, LLC'} selected> {shipperName === 'MetroHealth Specialty Pharmacy' || shipperName === 'MetroHealth Mail Order Pharmacy' ? 'Clockwork Logistics' : shipperName === 'UCSD Specialty Pharmacy (Kearny Mesa)' ? 'Atlas Transport (San Diego)' : 'KTD Transport and Property Management, LLC'} </option>
                      <option value='fedex'>FedEx</option>
                    </select>
                  </div>

                  {
                    carrierName === 'FedEx' &&
                    <div className='form-group'>
                      <label className='mb-2'>
                        Weight <span className='text-danger'>*</span>
                      </label>
                      <input className='form-control' type='text' name='weight' placeholder='Weight in lbs' value={formData?.weight} onChange={onInputChange} />
                    </div>
                  }
                </div>
              }

              <div className='row'>
                <div className='col col-md-6'>
                  <div className='card bg-light border'>
                    <div className='card-body pt-3 pb-0'>
                      <div className='form-group'>
                        <label className='mb-2'>
                          Service Type <span className='text-danger'>*</span>
                        </label>
                        <ul className='nav nav-pills'>
                          {shipperName === 'UCSD Specialty Pharmacy (Kearny Mesa)' && carrierName === 'FedEx' ?
                            fedExServiceType.map((item, index) => {
                              return (
                                <li key={index} className="nav-item ms-2 mb-2" onClick={() => setServiceType(item.name)} >
                                  <a className={serviceType === item?.name ? `nav-link active p-0` : `nav-link border border-primary p-0`} href='#!'>
                                    <label className="service_delivery_date_label" >{item?.serviceType}</label>
                                  </a>
                                </li>
                              )
                            })
                            : <>
                              {serviceTypeList?.length > 0 ? (
                                serviceTypeList?.map(({ name, service_min_time }, index) => {
                                  return (
                                    <li key={index} className="nav-item ms-2 mb-2" onClick={() => { setServiceType(name); setTime(service_min_time / 60) }} >
                                      <a className={serviceType === name ? `nav-link active p-0` : `nav-link border border-primary p-0`} href='#!'>
                                        {name?.toLowerCase() === `future` ? <>
                                          {/* <label className="service_delivery_date_label-1" onClick={handleLabel}> */}
                                          <label className="service_delivery_date_label-1">
                                            <DatePicker id="service_delivery_date" selected={serviceDeliveryStartDate} onChange={date => handleFutureDate(date)} minDate={new Date()} onKeyDown={e => e.preventDefault()} />
                                            <span ref={serviceDeliveryFutureRef}>{name}</span>
                                          </label>
                                        </> : <label className="service_delivery_date_label" onClick={handleFutureDateReset}>{name}</label>}
                                      </a>
                                      {serviceType?.toLowerCase() === `future` && name?.toLowerCase() === `future` && formData.service_delivery_date.length > 0 && <small className="text-center d-block">{formData.service_delivery_date}</small>}
                                    </li>
                                  );
                                })
                              ) : (
                                <li className='nav-item ms-2' onClick={() => setServiceType('Same Day')}>
                                  <a className='nav-link active' href='#!'>
                                    Same Day
                                  </a>
                                </li>
                              )}
                            </>}
                        </ul>
                        {isServiceDeliveryStartDateRequired && serviceType?.toLowerCase() === `future` && <small className='text-danger ms-2'>Date is required for Future Service Type</small>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col col-md-6'>
                  <div className='card bg-light border'>
                    <div className='card-body pt-3 pb-0'>
                      <div className='form-group'>
                        <label className='mb-2'>
                          Storage Type <span className='text-danger'>*</span>
                        </label>
                        <ul className='nav nav-pills'>
                          {storageTypesList?.length > 0 ? (
                            storageTypesList?.map((storage, index) => {
                              return (
                                <li key={index} className='nav-item ms-2' onClick={() => setStorageType(storage.name)}>
                                  <a className={storageType === storage.name ? `nav-link active` : `nav-link border border-primary`} href='#!'>
                                    {storage.name}
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <li className='nav-item ms-2' onClick={() => setStorageType('Room Temp')}>
                              <a className='nav-link active' href='#!'>
                                Room Temp
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isLabOrder && !(shipperName === 'UCSD Specialty Pharmacy (Kearny Mesa)' && carrierName === 'FedEx') && (


                <div className='row'>
                  <div className='form-group'>
                    <label className='mb-2'>Timeslot</label>
                    <ul className='nav nav-pills'>
                      {deliveryTimeSlot && deliveryTimeSlot?.length > 0 ? (
                        deliveryTimeSlot
                          ?.filter((timeSlot) => !(serviceType === 'Same Day' && timeSlot?.name !== 'Anytime' && timeSlot?.time < new Date().getHours()))
                          .map((timeSlot, index) => {
                            return (
                              <li key={index} className='nav-item ms-2' onClick={() => onHandleNavChange('delivery_timeslot', timeSlot.name)}>
                                <a className={(formData.delivery_timeslot?.length > 0 ? formData.delivery_timeslot === timeSlot.name : 'Anytime ' === timeSlot.name) ? `nav-link active` : `nav-link border border-primary`} href='#!'>
                                  {timeSlot.name}
                                </a>
                              </li>
                            );
                          })
                      ) : (
                        <li className='nav-item ms-2' onClick={() => onHandleNavChange('delivery_timeslot', 'Anytime')}>
                          <a className='nav-link active' href='#!'>
                            Anytime
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>


              )}
              <div className='row'>
                <div className='form-group'>
                  <label className='mb-2'>Delivery Instructions</label>
                  <textarea className='form-control' rows='2' name='delivery_instructions' placeholder='e.g. Requires cold storage' value={formData.delivery_instructions} onChange={onInputChange} />
                </div>
              </div>
              <div className='row'>
                <div className='col col-sm-12 col-md-4 mt-3'>
                  <div className='form-check form-switch'>
                    <input className='form-check-input' id='adultSignatureCheckbox' type='checkbox' name='adult_signature' value={formData.adult_signature} checked={formData.adult_signature} onChange={onInputChange} />
                    <label className='form-check-label' htmlFor='adultSignatureCheckbox'>
                      Adult Signature Required
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card'>
            <table className='table table-sm mb-0'>
              <thead>
                <tr>
                  <th className='text-center'></th>
                  <th>Reference Number</th>
                  <th>
                    Product Type <span className='text-danger'>*</span>
                  </th>
                  <th className='text-center'>
                    <button
                      className='btn btn-sm btn-outline-primary'
                      onClick={(e) => {
                        handleAddRecord(e);
                      }}>
                      <i className='fe fe-plus'></i>
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='list font-size-base'>
                {orderList &&
                  orderList?.map((data, index) => {
                    return (
                      <tr key={`${data}-${index}`}>
                        <td className='text-center'>{`#${index + 1}`}</td>
                        <td>
                          <div className='form-group mb-0'>
                            <input className='form-control' key={index} id='index' type='text' name={`order_number-${index}`} placeholder='e.g. 1112-12322' value={data.order_number} onChange={(e) => handleChangeRecord(index, e, 'orderNumber')} />
                          </div>
                        </td>
                        <td>
                          <div className='form-group mb-0'>
                            <select className='form-control' key={index} id='index' name={`product_type-${index}`} custom value={data.product_type} onChange={(e) => handleChangeRecord(index, e, 'productType')}>
                              {productTypes &&
                                productTypes?.map((type, index) => {
                                  return (
                                    <option key={index} value={type.name}>
                                      {type.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </td>
                        <td className='text-center'>
                          {index !== 0 && (
                            <button
                              className='btn btn-sm btn-outline-secondary'
                              onClick={(e) => {
                                e.preventDefault()
                                handleRemoveRecord(index);
                              }}>
                              <i className='fe fe-trash'></i>
                            </button>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className='mb-5'>
            <h4 className='header-title'>Drop Off location</h4>
            {!isEmpty(distance) && (
              <span className={(carrierName === 'FedEx' && distance?.shipment_miles <= 5000) ? 'text-success mt-2 mb-6' : Number(distance?.shipment_miles) < Number(distance?.shipper_miles) ? 'text-success mt-4' : 'text-danger mt-4'}>
                <i className='fe fe-info' />
                &nbsp;{`Delivery distance is ${distance?.shipment_miles} miles.`}
              </span>
            )}
          </div>

          <div className='row row-cols-1 row-cols-md-3 g-4'>
            {addressBook?.map((address, index) => {
              return (
                <div key={index} className='col mt-0'>
                  <div className={`card  ${address.color}`} onClick={() => handleSelectAddress(address)}>
                    {address?.savedAddress ? (
                      <div className='card-body pt-0'>
                        <div className="row">
                          <div className="col-11 pt-4">
                            <div><h3> {address.name} </h3></div>
                            <div>{address.address1}</div>
                            <div>{address.address2}</div>
                            <div>{address.city}, {address.state} {address.postal_code}</div>
                          </div>
                          <div className="col-1 pt-1">
                            <span style={{ cursor: 'pointer' }}>
                              <i className='fe fe-x-circle delete_address_book_btn fs-2' onClick={() => deleteAddressBookData(address)} />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='card-body'>
                        <h3>Add a new address </h3>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <hr />
          <Link className='btn btn-white' to='/shipments'>
            Cancel
          </Link>
          <button type='button' className='btn btn-primary ms-2' onClick={confirmShipment}>
            Add Shipment
          </button>
        </form>
        <BarcodeReader onError={handleError} onScan={handleScan} />
      </div>

      {/* Add Parcel */}
      <Modal show={modalParcel} onHide={() => showParcelModal(false)}>
        <Modal.Body>
          <Formik enableReinitialize={true} initialValues={parcelData} validationSchema={parcelSchema} onSubmit={handleAddParcel}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <select className='form-control' name='product_type' custom value={values?.product_type} onChange={handleChange} isInvalid={!!errors.product_type}>
                    {values?.product_type?.length <= 0 && (
                      <option hidden value='' disabled selected>
                        Select Product Type
                      </option>
                    )}
                    {productTypes?.map((type, index) => {
                      return (
                        <option key={index} value={type.name}>
                          {type.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.product_type && <div className='text-danger mt-2 ms-1 h5'>{errors.product_type}</div>}
                </div>
                <div class='d-flex justify-content-end'>
                  <button type='button' class='btn btn-light' onClick={() => showParcelModal(false)}>
                    Close
                  </button>
                  <button type='submit' class='btn btn-primary ms-2'>
                    Add
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>


      {/* Add new address  */}
      <Modal show={modalAddress} onHide={modalAddressClose}>
        <Modal.Body>
          <Modal.Title className='h1'> New Address</Modal.Title>
          <hr />
          <Formik enableReinitialize={true} initialValues={addressData} validationSchema={addressSchema} onSubmit={handleNewAddress}>
            {({ handleChange, handleSubmit, values, errors }) => (

              <form noValidate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='form-group'>
                    <label className='mb-2'>
                      Recipient Name<span className='text-danger'>*</span>
                    </label>
                    <AsyncCreatableSelect
                      name='name'
                      placeholder='First Name Last Name'
                      type='text'
                      id='name'
                      defaultValue={addressData.name ? { label: addressData.name, value: addressData.name } : false}
                      isClearable
                      loadOptions={items}
                      onChange={onChangeFunction}
                    />
                    {errors.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group'>
                    <label className='mb-2'>Care of / Attn </label>
                    <input className='form-control' type='text' name='secondary_name' placeholder='e.g. Jane Doe' value={values.secondary_name} onChange={handleChange} isInvalid={!!errors.secondary_name} />
                    {errors.secondary_name && <div className='text-danger mt-2 ms-1 h5'>{errors.secondary_name}</div>}
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>Company Name</label>
                      <input className='form-control' type='text' name='company' placeholder='e.g. ABC Company' value={values.company} onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>Email</label>
                      <input className='form-control' type='email' name='email' placeholder='e.g. address@example.com' value={values.email} onChange={handleChange} />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>Phone  <span className='text-danger'>*</span></label>
                      <Field name='phone' render={({ field }) => <MaskedInput {...field} mask={phoneNumberMask} id='phone' placeholder='( _ _ _ ) _ _ _ - _ _ _ _' type='text' onChange={handleChange} className={errors.phone ? 'form-control text-input error' : 'form-control  text-input'} />} isInvalid={!!errors.phone} />
                      {errors.phone && <div className='text-danger mt-2 ms-1 h5'>{errors.phone}</div>}{' '}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>
                        Address Line 1<span className='text-danger'>*</span>
                      </label>
                      <PlacesAutocomplete
                        name='address1'
                        value={values.address1}
                        onChange={(data) => {
                          handleAddress1Change(data, values);
                        }}
                        onSelect={handleAddressSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                          <div className='search-input-container col-12 p-0 mb-4'>
                            <input
                              {...getInputProps({
                                placeholder: 'e.g. 100 W Main St.',
                                className: 'form-control business-form',
                              })}
                              isInvalid={!!errors.address1}
                            />
                            {errors.address1 && <div className='text-danger mt-2 ms-1 h5'>{errors.address1}</div>}
                            <div className='autocomplete-container bg-light'>
                              {suggestions.map((suggestion) => {
                                const className = 'suggestion-item';
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                    })}>
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>Address Line 2</label>
                      <input className='form-control' type='text' name='address2' placeholder='e.g. 105 H Nort St.' value={values.address2} onChange={handleChange} />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>
                        City <span className='text-danger'>*</span>
                      </label>
                      <input className='form-control' type='text' name='city' placeholder='e.g. Seattle' value={values.city} onChange={handleChange} isInvalid={!!errors.city} />
                      {errors.city && <div className='text-danger mt-2 ms-1 h5'>{errors.city}</div>}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>
                        State <span className='text-danger'>*</span>
                      </label>
                      <input className='form-control' type='text' name='state' placeholder='e.g. WA' value={values.state} onChange={handleChange} isInvalid={!!errors.state} />
                      {errors.state && <div className='text-danger mt-2 ms-1 h5'>{errors.state}</div>}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>
                        Postal Code <span className='text-danger'>*</span>
                      </label>
                      <input className='form-control' type='text' maxLength='5' name='postal_code' placeholder='e.g. 21044' value={values.postal_code.replace(/[\\/]/g, '')} onChange={handleChange} isInvalid={!!errors.postal_code} />
                      {errors.postal_code && <div className='text-danger mt-2 ms-1 h5'>{errors.postal_code}</div>}
                    </div>
                  </div>
                </div>
                {!isEmpty(distance) && values?.address1?.length > 0 && (
                  <span className={(carrierName === 'FedEx' && distance?.shipment_miles <= 5000) ? 'text-success mt-2 mb-6' : Number(distance?.shipment_miles) < Number(distance?.shipper_miles) ? 'text-success mt-2 mb-6' : 'text-danger mt-2 mb-6'}>
                    {' '}
                    <i className='fe fe-info' />
                    &nbsp;{`Delivery distance is ${distance?.shipment_miles} miles.`}
                  </span>
                )}

                <div className='row mt-4 ms-2'>
                  <div className='form-check form-switch'>
                    <input className='form-check-input' id='save_address' type='checkbox' name='save_address' value={values.save_address} onChange={handleChange} />
                    <label className='form-check-label' htmlFor='save_address'>
                      Save to Favorites
                    </label>
                  </div>
                </div>

                <div class='d-flex justify-content-end'>
                  <button type='button' class='btn btn-light' onClick={modalAddressClose}>
                    Close
                  </button>
                  <button type='submit' className='btn btn-primary ms-2' disabled={carrierName === 'FedEx' ? distance?.shipment_miles >= 5000 : Number(distance?.shipment_miles) > Number(distance?.shipper_miles)}  >
                    Save
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={modalConfirmOrder} onHide={() => showConfirmOrderModal(false)}>
        <Modal.Body>
          <h2 className='text-center text-danger font-weight-bold opacity-75'>Confirm Delivery Request</h2>
          {serviceType &&
            <p className='text-center font-weight-bold mt-2'>
              <strong className="font-weight-bold">
                {SERVICE_TYPE[serviceType?.toLowerCase()]?.serviceType || serviceType}: <span className="text-muted ps-2">{SERVICE_TYPE[serviceType?.toLowerCase()]?.value || `Delivery by Today, ${moment().add(time, "hour").format('hh:mma')}`}</span>
              </strong>
            </p>}
          {
            (shipperName === 'MetroHealth Mail Order Pharmacy' || shipperName === 'MetroHealth Specialty Pharmacy') &&
            <div className='text-center font-weight-bold mt-2'>
              <strong>Courier: <span className="text-muted ps-2">{carrierName}</span></strong>
            </div>
          }
          <hr />
          <div className='row'>
            <div className='col '>
              <h3 className='font-weight-bold'>Sender</h3>
              <div className='text-nowrap font-weight-bold'>{props.shipper.name}</div>
              <div className='text-muted'>
                <div>{shipperDetails.address?.address1}</div>
                <div>{shipperDetails.address?.address2}</div>
                <div>
                  {shipperDetails.address?.city}, {shipperDetails.address?.state} {shipperDetails.address?.postal_code}`
                </div>
              </div>
            </div>
            <div className='col'>
              <h3 className='font-weight-bold'>Recipient</h3>
              <div className='text-nowrap font-weight-bold'>{isEmpty(selectedAddress) ? formData.contact_name : selectedAddress.name}</div>
              <div className='text-muted'>
                <div>{isEmpty(selectedAddress) ? formData?.address1?.trim() : selectedAddress.address1?.trim()}</div>
                <div>{isEmpty(selectedAddress) ? formData?.address2?.trim() : selectedAddress.address2?.trim()}</div>
                <div>
                  {isEmpty(selectedAddress) ? formData?.city?.trim() : selectedAddress.city?.trim()}, {isEmpty(selectedAddress) ? formData?.state?.trim() : selectedAddress.state?.trim()} {isEmpty(selectedAddress) ? formData?.postal_code : selectedAddress.postal_code}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button className='btn btn-light' type='button' onClick={() => showConfirmOrderModal(false)}>
              Back
            </button>
            <button className='btn btn-success ms-2' type='button' onClick={onAddNewShipment}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Spinner>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
    shippers: state.shippers,
  };
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(connect(mapStateToProps)(AddShipments));
