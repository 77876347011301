/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

import * as Yup from 'yup';
import moment from 'moment';
import swal from 'sweetalert';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import ImageGallery from 'react-image-gallery';
import { sortBy, forEach, isEmpty } from 'lodash';
import { GoogleApiWrapper } from 'google-maps-react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { collection, onSnapshot, where, query } from 'firebase/firestore';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { db } from '../../firebaseConfig';
import Avatar from '../../components/avatar';
import Spinner from '../../components/spinner';
import { StatusHeader } from '../../components/statusBadge';
import ListGroupItem from '../../components/listGroupItem';
import { isOwner, isEditor } from '../../helpers/userHelper';
import { toLocalDate, getDateTimeDiffernce, updateToken, getEstimatedTimeslot } from '../../helpers/utils';
import {
  getBatchShipments,
  getPatientTrackingUrl,
  getPatientTrackingUrlById,
  getDistance,
  getShipmentsById,
  addEditShipper,
  getServiceTypeById,
  getDefaultServiceType,
  getHistoryById,
  getFeedbackById,
  updateShipmentById,
  updateShipmentStatusById,
  getProductTypeById,
  getDefaultProductType,
  getDefaultStorageById,
  getDefaultStorageType,
} from '../../services/index';
import ReactToPrint from 'react-to-print';
import PrintShipment from './printShipment';

let QRCode = require('qrcode.react');
let dayjs = require('dayjs');
let utc = require('dayjs/plugin/utc');
let localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(utc);
dayjs.extend(localizedFormat);

const ShipmentDetail = (props) => {
  const ship_to_validations = Yup.object().shape({
    email: Yup.string().email('Email is invalid.'),
    contact_name: Yup.string()
      .required('Name is required.')
      .matches(/(\d*[a-zA-Z]+\d*)+/, 'Name cannot contain only numbers'),
    secondary_contact_name: Yup.string().matches(/(\d*[a-zA-Z]+\d*)+/, 'C/O cannot contain only numbers'),
    address1: Yup.string().required('Address 1 is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    postal_code: Yup.string().required('Postal Code is required.'),
  });

  const default_status = {
    status: '',
    notify: true,
    description: '',
  };

  const [modalStatus, showStatusModal] = useState(false);
  const [modalCancel, showCancelModal] = useState(false);
  const [modalHistory, showHistoryModal] = useState(false);
  const [modalParcel, showParcelModal] = useState(false);
  const [modalRecipient, showRecipientModal] = useState(false);
  const [modalServiceType, showServiceTypeModal] = useState(false);
  const [modalstorageType, showStorageTypeModal] = useState(false);
  const [modalInstructions, showInstructionModal] = useState(false);
  const [adultSignatureModal, setAdultSignatureModal] = useState(false);

  const [qrCode, setQrCode] = useState();
  const [distance, setDistance] = useState({});
  const [location, setLocation] = useState({});
  const [shipment, setShipment] = useState({});
  const [trackingUrl, setTrackingUrl] = useState('');
  const [loading, showSpinner] = useState(false);
  const [serviceType, setServiceType] = useState([]);
  const [storageType, setStorageType] = useState('');
  const [storageTypesList, setStorageTypesList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [feedbackList, setFeedbackList] = useState({});
  const [productTypes, setProductTypes] = useState([]);
  const [deliveryProofs, setDeliveryProofs] = useState([]);
  const [isBatchShipment, setIsBatchShipment] = useState([]);
  const [shipmentTracking, setShipmentTracking] = useState({});
  const [defaultProductType, setDefaultProductType] = useState('');
  const [orderList, setOrderList] = useState([{ order_number: '', product_type: '' }]);
  const [batchEdit, setBatchEdit] = useState(false);
  const [batchUpdate, setBatchUpdate] = useState(false);
  const [deliveryMessage, setDeliveryMessage] = useState('');
  const [patientLocation, setPatientLocation] = useState({});
  const [podSignature, setPoDSignature] = useState({});
  const [shipmentStatus, setShipmentStatus] = useState('');
  const [isVerifiedWithOtp, setVerifiedWithOtp] = useState(false);
  const [recipientDefaultvalues, setRecipientDefaultvalues] = useState({});
  const [currentShipmentStatus, setShipmentCurrentStatus] = useState(default_status);
  const [phoneNumber, setPhoneNumber] = useState({
    data: '',
    is_valid: true,
  });
  const [futureDeliveryDate, setFutureDeliveryDate] = useState(null);
  const shipmentPrint = props.location.state;
  const [shipmentFedexModal, setShipmentFedexModal] = useState(false);
  const [updateAdultSign, setUpdateAdultSign] = useState('')

  useEffect(() => {
    getShipment();
    getFeedback();
    getStorageType();
  }, []);

  useEffect(() => {
    getShipmentDistance();
  }, [patientLocation]);

  useEffect(() => {
    setRecipientDefaultvalues({ ...shipment.ship_to });
  }, [shipment.ship_to]);

  useEffect(() => {
    setTimeout(() => {
      let printBtn = document.getElementById('printBtn');
      if (printBtn && shipmentPrint && qrCode) {
        printBtn.click();
      }
    }, 1000);
  }, [qrCode]);


  useEffect(() => {
    if (shipment?.carrier?.name.toLowerCase() === 'fedex' && shipmentPrint) {
      setShipmentFedexModal(true);
    }
    setTimeout(() => {
      if (shipment?.carrier?.name.toLowerCase() === 'fedex' && shipmentPrint) {
        getShipment();
        setShipmentFedexModal(false);
      }
    }, 5000);
    setTimeout(() => {
      let printBtnFedex = document.getElementById('printBtnFedex');
      if (printBtnFedex && shipmentPrint && shipment?.carrier?.name) {
        printBtnFedex.click();
      }
    }, 6000);
  }, [shipment?.carrier?.name]);

  useEffect(() => {
    document.title = `Phox Health - Shipment #${shipment?.number} - ${shipment.status === 'Exception' ? 'Cancelled' : shipment.status}`;
  }, [shipment]);

  const getShipment = async () => {
    showSpinner(true);
    const response = await getShipmentsById(props.match.params.id);
    if (response && (response.status === 200 || response.status === 201)) {
      let shipmentData = response?.data;
      shipmentData.status_colour = await getStatusColour(shipmentData?.status);
      setShipmentStatus(shipmentData.status);
      setUpdateAdultSign(shipmentData.signature_required)
      await setShipment(shipmentData);
      const newValue = shipmentData?.ship_to?.phone && shipmentData?.ship_to?.phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
      setPhoneNumber({
        data: shipmentData?.ship_to?.phone,
        is_valid: newValue.length > 0 && newValue.length < 10 ? false : true,
      });
      await setPatientLocation({
        latitude: response?.data?.ship_to?.location?._latitude,
        longitude: response?.data?.ship_to?.location?._longitude,
      });
      if (shipmentData?.estimated_delivery_date) {
        setFutureDeliveryDate(shipmentData.estimated_delivery_date);
      }

      const parcelItems = [];
      const parcels = !isEmpty(shipmentData?.parcels) && Object.values(shipmentData?.parcels[0])[0];
      parcels &&
        parcels.forEach((item) => {
          let parcelItem = {};
          parcelItem.product_type = item?.productType;
          parcelItem.order_number = item?.reference_number;
          parcelItems.push(parcelItem);
        });
      setOrderList(parcelItems);
      getPatientTrackingDetails(shipmentData?.shipper_id);
      getProductsType(shipmentData?.shipper_id);
      getServiceType(shipmentData?.shipper_id);
      setShipmentCurrentStatus({ ...currentShipmentStatus, status: shipmentData?.status });
      const source = `${shipmentData?.ship_from?.address1}+${shipmentData?.ship_from?.address2}+${shipmentData?.ship_from?.city}+${shipmentData?.ship_from?.state}+${shipmentData?.ship_from?.postal_code}`;
      const destination = `${shipmentData?.ship_to?.address1}+ ${shipmentData?.ship_to?.address2}+${shipmentData?.ship_to?.city}+${shipmentData?.ship_to?.state}+${shipmentData?.ship_to?.postal_code}`;
      const location = {
        source: source,
        destination: destination,
      };
      setLocation(location);
      let qrCode = '';
      if (response?.data?.ship_from?.group_name.toLowerCase() === 'ucsd') {
        let splited_ref = []
        let ref_numbers = response?.data?.parcels[0]?.items
        ref_numbers.map((item) => {
          return splited_ref.push(item?.reference_number)
        })
        const allReferenceNumber = splited_ref.join(',')
        qrCode = `${shipmentData?.order_number}^${shipmentData?.ship_to?.contact_name}^${shipmentData?.ship_to?.phone}^${shipmentData?.ship_to?.email}^${shipmentData?.ship_to?.address1}^${shipmentData.ship_to.city}^${shipmentData?.ship_to?.state}^${shipmentData?.ship_to?.postal_code}^${allReferenceNumber}`;
      } else {
        qrCode = `${shipmentData?.order_number}^${shipmentData?.ship_to?.contact_name}^${shipmentData?.ship_to?.phone}^${shipmentData?.ship_to?.email}^${shipmentData?.ship_to?.address1}^${shipmentData.ship_to.city}^${shipmentData?.ship_to?.state}^${shipmentData?.ship_to?.postal_code}`;
      }
      setQrCode(qrCode);
      getStatusHistory(shipmentData);
      showSpinner(false);
    } else {
      if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
        await updateToken();
      }
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
      showSpinner(false);
    }
  };

  useEffect(() => {
    if (shipmentStatus?.length > 0 && shipmentStatus === 'Out for Delivery') {
      onSnapshot(query(collection(db, 'shipments'), where('id', '==', props.match.params.id)), (objects) => {
        let shipment = {};
        objects.forEach((item) => {
          shipment = item.data();
        });
        setShipmentTracking(shipment);
      });
    }
  }, [shipmentStatus]);

  const getProductsType = async (shipper_id) => {
    showSpinner(true);
    try {
      const response = await getProductTypeById(shipper_id);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          setProductTypes(response?.data);
          setDefaultProductType(response?.data && response?.data[0].name);
          showSpinner(false);
        } else {
          const defaultProductTypes = await getDefaultProductType();
          setProductTypes(defaultProductTypes?.data);
          setDefaultProductType(defaultProductTypes?.data && defaultProductTypes?.data[0].name);
          showSpinner(false);
        }
      } else {
        showSpinner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBatchShipmentDetails = async (batch_number, status) => {
    const response = await getBatchShipments(batch_number, status);
    if ((response.status === 200 || response.status === 201) && response?.data.length) {
      await setIsBatchShipment(response?.data);
    }
  };

  const getPatientTrackingDetails = async (id) => {
    showSpinner(true);
    const response = await getPatientTrackingUrlById(id);
    if ((response.status === 200 || response.status === 201) && response?.data.length) {
      setTrackingUrl(response?.data && response?.data[0]?.value);
    } else {
      const response = await getPatientTrackingUrl();
      if ((response.status === 200 || response.status === 201) && response?.data) {
        setTrackingUrl(response?.data && response?.data[0]?.value);
      }
    }
    setTimeout(() => {
      showSpinner(false);
    }, 1600);
  };

  const getShipmentDistance = async () => {
    if (!isEmpty(patientLocation)) {
      const request = {
        ship_from: {
          location: {
            _latitude: shipment?.ship_from?.location?._latitude,
            _longitude: shipment?.ship_from?.location?._longitude,
          },
          shipper_id: props?.shipper?.id,
        },
        ship_to: {
          location: {
            _latitude: patientLocation?.latitude,
            _longitude: patientLocation?.longitude,
          },
        },
      };
      try {
        const response = await getDistance(request);
        if (response && (response.status === 200 || response.status === 201)) {
          await setDistance({ distance, ...response?.data });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getStatusHistory = async (shipment) => {
    showSpinner(true);
    const response = await getHistoryById(props.match.params.id);
    if (response && (response.status === 200 || response.status === 201)) {
      setHistoryList(response?.data);
      const verificationCode = shipment?.verification_code;
      const isDelivered = !!response?.data.find((item) => item.status === 'Delivered');
      const message = response?.data.find((item) => item.status === 'Delivered')?.description;
      const signatureDetails = response?.data.find((item) => item.status === 'Delivered')?.pod_signature;
      if (isDelivered) {
        let proofs = [];
        let data = response?.data.find((item) => Array.isArray(item?.attachments) && item?.attachments?.length);
        if (data) {
          if (data.attachments[0].includes('{"url"')) {
            data = JSON.parse(data.attachments[0]);
            data = Object.values(data);
            proofs.push({ original: data, thumbnail: data });
            setDeliveryProofs(proofs);
          } else {
            const proofDetails = data?.attachments;
            if (Array.isArray(proofDetails) && proofDetails.length > 0) {
              forEach(proofDetails, (item) => {
                proofs.push({ original: item, thumbnail: item });
              });
              setDeliveryProofs(proofs);
            }
          }
        }
        if (Number(message) === verificationCode) {
          setDeliveryMessage(`Verified with OTP - ${verificationCode}`);
          setVerifiedWithOtp(true);
        } else {
          setDeliveryMessage(message);
          setVerifiedWithOtp(false);
        }
        setPoDSignature(signatureDetails);
      }
      showSpinner(false);
    } else {
      showSpinner(false);
    }
  };

  const getNpsRating = (feedback) => {
    const nps = Number(feedback.nps);
    if (nps <= 6) {
      setFeedbackList({ ...feedback, nps_score: 'text-danger' });
    } else if (nps >= 7 && nps <= 8) {
      setFeedbackList({ ...feedback, nps_score: 'text-warning' });
    } else if (nps >= 9 && nps <= 10) {
      setFeedbackList({ ...feedback, nps_score: 'text-success' });
    }
  };

  const getStatusColour = (status) => {
    return status === 'Delivered'
      ? 'text-success'
      : status === 'Out for Delivery' || status === 'Out for Return'
        ? 'text-orange'
        : status === 'Returned'
          ? 'text-purple'
          : status === 'Exception' || status === 'Cancelled'
            ? 'text-danger'
            : 'text-primary';
  };

  const getFeedback = async () => {
    showSpinner(true);
    const response = await getFeedbackById(props.match.params.id);
    if (response && (response.status === 200 || response.status === 201)) {
      const data = response.data && response.data[0];
      await setFeedbackList(data);
      if (data && data?.nps && data.nps !== '') {
        await getNpsRating(data);
      }
      showSpinner(false);
    } else {
      showSpinner(false);
    }
  };

  const getServiceType = async (shipperId) => {
    try {
      const response = await getServiceTypeById(shipperId);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          const serviceTypes = sortBy(response?.data, 'sort_order');
          setServiceType(serviceTypes);
        } else {
          const defaultServiceResponse = await getDefaultServiceType();
          const defaultServiceTypes = sortBy(defaultServiceResponse?.data, 'sort_order');
          setServiceType(defaultServiceTypes);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getStorageType = async () => {
    setStorageType('');
    showSpinner(true);
    try {
      const response = await getDefaultStorageById(props.shipper.id);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          const storageTypes = await sortBy(response?.data, 'sort_order');
          setStorageType(storageTypes && storageTypes[0]?.name);
          setStorageTypesList(storageTypes);
          showSpinner(false);
        } else {
          const defaultStorage = await getDefaultStorageType();
          const defaultStorageTypes = await sortBy(defaultStorage?.data, 'sort_order');
          setStorageType(defaultStorageTypes && defaultStorageTypes[0]?.name);
          setStorageTypesList(defaultStorageTypes);
          showSpinner(false);
        }
      } else {
        showSpinner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const updateShipment = async () => {
    showSpinner(true);
    const response = await updateShipmentById(props.match.params.id, shipment);
    if (response && (response.status === 200 || response.status === 201)) {
      getShipment();
      showRecipientModal(false);
      showSpinner(false);
    } else {
      showRecipientModal(false);
      showSpinner(false);
    }
  };

  const updateRecipient = async (ship_to) => {
    if (!isEmpty(distance) && Number(distance?.shipment_miles) < Number(distance?.shipper_miles)) {
      shipment.shipment_update_notify = true;
      shipment.distance = isEmpty(distance) ? '' : distance?.shipment_miles.toString();
      shipment.ship_to = ship_to;
      shipment.ship_to.address1 = ship_to?.address1?.trim();
      shipment.ship_to.address2 = ship_to?.address2?.trim();
      shipment.ship_to.city = ship_to?.city?.trim();
      shipment.ship_to.state = ship_to?.state?.trim();
      shipment.ship_to.email = ship_to.email?.toLowerCase()?.trim();
      shipment.ship_to.phone = ship_to.phone;
      setShipment(shipment);
      await updateShipment();
    } else {
      toast.error(`Order #(${shipment?.number}) address is more than ${distance?.shipper_miles} miles! Please choose a different address.`);
    }
  };

  const updateSpecialInstructions = async (shipment) => {
    showSpinner(true);
    let delivery_instructions = '';
    if (shipment.refrigeration_required) {
      delivery_instructions =
        shipment.special_instruction?.length > 0 ? `${shipment.special_instruction}, Refrigeration is required.` : 'Refrigeration is required.';
    } else {
      delivery_instructions = shipment.special_instruction;
    }
    const request = {
      special_instruction: shipment?.special_instruction || '',
      delivery_instructions,
      shipment_update_notify: true,
    };
    const response = await updateShipmentById(props.match.params.id, request);
    if (response && (response.status === 200 || response.status === 201)) {
      toast.success(`Delivery Instructions has been updated for Shipment #(${shipment?.number}) `);
      showInstructionModal(false);
      await getShipment();
      showSpinner(false);
    } else {
      showSpinner(false);
    }
  };

  const updateStatus = async (status) => {
    if (status.status === 'Exception') {
      await confirmShipmentCancel(status);
    } else {
      let data = status;
      let url = `shipment/${props.match.params.id}/status`;

      data.notify = true;
      showSpinner(true);
      const response = await updateShipmentStatusById(url, data);
      if (response && (response.status === 200 || response.status === 201)) {
        showStatusModal(false);
        getShipment();
        getStatusHistory();
        showSpinner(false);
        setBatchUpdate(false);
      } else {
        showSpinner(false);
        setBatchUpdate(false);
      }
    }
  };

  const confirmShipmentCancel = (status) => {
    let data = status;
    let url = '';
    if (batchUpdate) {
      url = `shipment/${props.match.params.id}/exception/batch?status=${shipment?.status?.toLowerCase()}`;
    } else {
      url = `shipment/${props.match.params.id}/exception`;
    }
    data.notify = true;
    swal({
      title: `Cancel`,
      text: `Do you want to cancel the shipment ${shipment?.number}`,
      buttons: ['No', 'Yes'],
      icon: 'error',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const response = await updateShipmentStatusById(url, data);
        if (response && (response.status === 200 || response.status === 201)) {
          showStatusModal(false);
          getShipment();
          getStatusHistory();
          showSpinner(false);
          setBatchUpdate(false);
        } else {
          showSpinner(false);
          setBatchUpdate(false);
        }
      } else {
        showSpinner(false);
      }
    });
  };

  const updateServiceType = async (shipment) => {
    swal({
      title: `Alert`,
      text: `Editing service type will cancel the current shipment and create a new shipment request. Do you wish to continue?`,
      buttons: ['No', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        const data = {
          description: '',
          notify: true,
          status: 'Exception',
        };
        const request = {
          status: 'Ready for Pickup',
          distance: shipment.distance,
          refrigeration_required: shipment.refrigeration_required,
          signature_required: shipment.signature_required,
          order_code: shipment.order_code,
          product_type: shipment.product_type,
          storage_type: shipment.storage_type,
          order_number: shipment.order_number.trim(),
          special_instruction: shipment.special_instruction,
          delivery_instructions: shipment.delivery_instructions,
          delivery_timeslot: shipment.delivery_timeslot,
          service_type: shipment.service_type.length > 0 ? shipment.service_type : 'Same Day',
          parcels: shipment.parcels,
          ship_to: shipment.ship_to,
          ship_from: shipment.ship_from,
        };
        shipment.service_type?.toLowerCase() === 'future' && (request.service_delivery_date = futureDeliveryDate);
        showSpinner(true);
        const url = `shipment/${props.match.params.id}/exception`;
        const response = await updateShipmentStatusById(url, data);
        if (response && (response.status === 200 || response.status === 201)) {
          const result = await addEditShipper('shipment', request);
          if (result && (result.status === 200 || result.status === 201)) {
            props.history.push('/shipments');
            toast.success(`# ${shipment?.number} for ${shipment.ship_to.contact_name} details has been updated`);
            showServiceTypeModal(false);
          } else {
            showServiceTypeModal(false);
            showSpinner(false);
          }
        } else {
          showSpinner(false);
        }
      }
    });
  };

  const updateStorageType = async (shipment) => {
    swal({
      title: `Alert`,
      text: `Do you want to update Storage Type, click YES to continue`,
      buttons: ['No', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        let delivery_instructions = shipment.special_instruction;
        let refrigeration_flag = false;
        if ((shipment?.storage_type?.toLowerCase() === 'refridge' || shipment?.storage_type?.toLowerCase() === 'refrigeration')) {
          delivery_instructions = shipment?.special_instruction?.length > 0 ? `${shipment?.special_instruction},  Refrigeration is required.` : 'Refrigeration is required.';
          refrigeration_flag = true
        } else {
          delivery_instructions = shipment.special_instruction
        }
        const request = {
          status: 'Ready for Pickup',
          distance: shipment.distance,
          refrigeration_required: refrigeration_flag,
          signature_required: shipment.signature_required,
          order_code: shipment.order_code,
          product_type: shipment.product_type,
          storage_type: shipment.storage_type,
          delivery_instructions,
          order_number: shipment.order_number.trim(),
          special_instruction: shipment.special_instruction,
          delivery_timeslot: shipment.delivery_timeslot,
          service_type: shipment.service_type.length > 0 ? shipment.service_type : 'Same Day',
          parcels: shipment.parcels,
          ship_to: shipment.ship_to,
          ship_from: shipment.ship_from,
        };
        shipment.service_type?.toLowerCase() === 'future' && (request.service_delivery_date = futureDeliveryDate);
        showSpinner(true);
        const response = await updateShipmentById(props.match.params.id, request);
        if (response && (response.status === 200 || response.status === 201)) {
          toast.success(`Storage Type has been updated for Shipment #(${shipment?.number}) `);
          showInstructionModal(false);
          await getShipment();
          showStorageTypeModal(false)
          showSpinner(false);
        } else {
          showSpinner(false);
          toast.error(`Something went wrong for Shipment #(${shipment?.number}) `);
          showStorageTypeModal(false)
        }
      }
    });
  };
  const UpdateParcelCount = async () => {
    let parcelData = [];
    orderList.forEach((item, index) => {
      parcelData.push({
        description: `Item ${index}`,
        productType: item?.product_type?.length > 0 ? item.product_type : defaultProductType,
        reference_number: item?.order_number,
      });
    });
    if (parcelData[0]?.productType?.length > 0) {
      swal({
        title: `Alert`,
        text: `Your parcel count will be updated to ${orderList?.length}. Do you wish to continue?`,
        buttons: ['No', 'Yes'],
        icon: 'warning',
        dangerMode: true,
      }).then(async (status) => {
        if (status) {
          const updatedParcelData = [{ items: parcelData }];
          const request = { ...shipment, parcels: updatedParcelData };
          showSpinner(true);
          const response = await updateShipmentById(props.match.params.id, request);
          if (response && (response.status === 200 || response.status === 201)) {
            getShipment();
            showSpinner(false);
            showParcelModal(false);
          } else {
            showSpinner(false);
          }
        }
      });
    } else {
      toast.error(`Atleast 1 Parcel is required`);
    }
  };

  const ResetParcelCount = async () => {
    const parcelItems = [];
    const parcels = !isEmpty(shipment?.parcels) && Object.values(shipment?.parcels[0])[0];
    parcels &&
      parcels.forEach((item) => {
        let parcelItem = {};
        parcelItem.product_type = item?.productType;
        parcelItem.order_number = item?.reference_number;
        parcelItems.push(parcelItem);
      });
    setOrderList(parcelItems);
    showParcelModal(false);
  };

  const onCancelShipment = async (status) => {
    showSpinner(true);
    const data = {
      description: status?.description,
      notify: true,
      status: 'Exception',
    };
    const url = `shipment/${props.match.params.id}/exception`;
    const response = await updateShipmentStatusById(url, data);
    if (response && (response.status === 200 || response.status === 201)) {
      getShipment();
      getStatusHistory();
      showCancelModal(false);
    } else {
      showSpinner(false);
      showCancelModal(false);
    }
  };

  const updateHistory = async (status) => {
    showSpinner(true);
    let url;
    if (batchEdit) {
      isBatchShipment.forEach(async (batch, index) => {
        url = `shipment/${batch.id}/status-history`;
        const editStatusCode = await editDescription(url, status);
        if (index === 0 && editStatusCode) {
          showHistoryModal(false);
          setBatchEdit(false);
          getShipment();
          getStatusHistory();
          showSpinner(false);
        }
      });
    } else {
      url = `shipment/${props.match.params.id}/status-history`;
      const editStatusCode = await editDescription(url, status);
      if (editStatusCode) {
        showHistoryModal(false);
        setBatchEdit(false);
        getShipment();
        getStatusHistory();
        showSpinner(false);
      }
    }

    showSpinner(false);
  };

  const editDescription = async (url, status) => {
    const data = { description: status.description, status: status.status };
    const response = await updateShipmentStatusById(url, data);
    if (response && (response.status === 200 || response.status === 201)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddressSelect = async (value) => {
    const selectedAddress = await geocodeByAddress(value);
    const LatLong = await getLatLng(selectedAddress[0]);
    let street_number = '';
    let sub_locality1 = '';
    let sub_locality2 = '';
    let sub_locality3 = '';
    let sub_locality4 = '';
    let route = '';
    let city = '';
    let state = '';
    let postal_code = '';
    let location = {};
    if (LatLong) {
      location._latitude = LatLong.lat;
      location._longitude = LatLong.lng;
      await setPatientLocation({
        latitude: LatLong.lat,
        longitude: LatLong.lng,
      });
    }
    !!selectedAddress[0].address_components.length &&
      selectedAddress[0].address_components.forEach((address) => {
        if (address.types.includes('street_number')) street_number = address.short_name;
        if (address.types.includes('sublocality_level_3')) sub_locality1 = address.short_name;
        if (address.types.includes('sublocality_level_2')) sub_locality2 = address.short_name;
        if (address.types.includes('sublocality_level_1')) sub_locality3 = address.short_name;
        if (address.types.includes('country')) sub_locality4 = address.long_name;
        if (address.types.includes('route')) route = address.short_name;
        if (address.types.includes('locality')) city = address.short_name;
        if (address.types.includes('administrative_area_level_1')) state = address.short_name;
        if (address.types.includes('postal_code')) postal_code = address.long_name;
      });

    setRecipientDefaultvalues({
      ...recipientDefaultvalues,
      address1: street_number ? `${street_number} ${route}` : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route} ${sub_locality4}`,
      postal_code,
      city,
      state,
      location,
    });
  };

  const autoHandleChange = (address) => {
    setRecipientDefaultvalues({
      ...recipientDefaultvalues,
      address1: address,
    });
  };

  const resetAddress = () => {
    showRecipientModal(false);
    setRecipientDefaultvalues(shipment.ship_to);
    setDistance({});
  };

  const toggleModal = async (name) => {
    if (name === 'recipient') {
      await getShipmentDistance();
      showRecipientModal(true);
    } else if (name === 'status' || name === 'history') {
      if (shipment?.batch_number > 0) getBatchShipmentDetails(shipment?.batch_number, shipment?.status);
      if (name === 'history') {
        showHistoryModal(true);
      }
      if (name === 'status') {
        showStatusModal(true);
      }
    }
  };

  const handleAddRecord = (e) => {
    e.preventDefault();
    const values = [...orderList];
    values.push({
      product_type: '',
      order_number: '',
    });
    setOrderList(values);
  };

  const handleChangeRecord = (index, event, name) => {
    const values = [...orderList];
    if (name === 'orderNumber') {
      values[index].order_number = event.target.value;
    } else if (name === 'productType') {
      values[index].product_type = event.target.value;
    }
    setOrderList(values);
  };

  const handleRemoveRecord = (index) => {
    const values = [...orderList];
    values.splice(index, 1);
    setOrderList(values);
  };



  const updateSignature = () => {
    setUpdateAdultSign(prev => !prev)
  }


  const updateAdultSignature = () => {
    swal({
      title: `Alert`,
      text: `Do you wish to continue?`,
      buttons: ['No', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        const request = {
          status: 'Ready for Pickup',
          distance: shipment.distance,
          refrigeration_required: shipment.refrigeration_required,
          signature_required: updateAdultSign,
          order_code: shipment.order_code,
          product_type: shipment.product_type,
          storage_type: shipment.storage_type,
          order_number: shipment.order_number.trim(),
          special_instruction: shipment.special_instruction,
          delivery_instructions: shipment.delivery_instructions,
          delivery_timeslot: shipment.delivery_timeslot,
          service_type: shipment.service_type.length > 0 ? shipment.service_type : 'Same Day',
          parcels: shipment.parcels,
          ship_to: shipment.ship_to,
          ship_from: shipment.ship_from,
        };
        showSpinner(true);
        const response = await updateShipmentById(props.match.params.id, request);
        if (response && (response.status === 200 || response.status === 201)) {
          toast.success(`Adult Signature Required has been updated for Shipment #(${shipment?.number}) `);
          showInstructionModal(false);
          await getShipment();
          setAdultSignatureModal(false)
          showSpinner(false);
        } else {
          showSpinner(false);
          toast.success(`Something went wrong for Shipment #(${shipment?.number}) `);
          setAdultSignatureModal(false)
        }
      }
    });

  }



  let signValue = shipment?.signature_required === true ? "Required" : "Not Required"



  const isCancel = shipment?.status === 'Ready for Pickup';
  const isEditAccess = isOwner(props?.shipper) || isEditor(props?.shipper);

  const componentRef = useRef();

  const uspsMailClass = parseFloat(shipment.weight) < 1.0 ? 'First Class' : 'Priority';

  const fedExServiceType = shipment?.service_type === 'GROUND_HOME_DELIVERY' ? 'Ground Home Delivery' : 'Standard Overnight'

  if (shipment?.carrier?.alias === 'fedex') {
    shipment.tracking_url = `https://www.fedex.com/fedextrack/?trknbr=${shipment.ext_tracking_number}`;
  }
  else if (shipment?.carrier?.alias === 'USPS') {
    shipment.tracking_url = `https://www.stamps.com/tracking-details/?confirmation=${shipment.ext_tracking_number}`;
  }

  return (
    <Spinner display={loading}>
      <div className='header'>
        <div className='container-fluid'>
          <div className='header-body d-flex justify-content-between'>
            <div className='d-flex gap-4  align-items-end'>
              <h1 className='header-title'>{shipment.ship_to?.contact_name}</h1>
              <div className='border-2 border-end border-light pe-2'>
                <div>
                  {shipment.ship_to?.address1}
                  {shipment?.carrier?.alias.toLowerCase() !== 'usps' && <>{isEditAccess && isCancel && <i className='fe fe-edit-2 ms-2 text-muted' onClick={() => toggleModal('recipient')}></i>}</>}
                </div>
                <div>{shipment.ship_to?.address2}</div>
                <div>
                  {shipment.ship_to?.city}, {shipment.ship_to?.state} {shipment.ship_to?.postal_code}
                </div>
              </div>
              <div>
                <div>{shipment.ship_to?.email} </div>
                <div>{shipment.ship_to?.phone}</div>
                <div>{shipment.ship_to?.secondary_contact_name && `C/O ${shipment.ship_to?.secondary_contact_name}`}</div>
              </div>
            </div>
            <div className='d-flex align-items-center align-items-end'>
              <h2 className='text-muted me-2 m-0'>#{shipment?.number}</h2>
              <StatusHeader status={shipment.status} size='display-4' />
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='card'>
          <div className='card-body'>
            <div className='row d-flex align-items-center'>
              <div className='col col-auto'>
                <i className='fe fe-circle text-primary bg-primary rounded-circle fs-6' />
              </div>
              <div className='col'>
                <hr className='text-primary timeline_progress' />
              </div>
              <div className='col col-auto'>
                <i className={`fe fe-circle text-warning fs-6 ${shipment?.status === 'Ready for Pickup' || shipment?.status === 'Exception' ? '' : 'bg-warning rounded-circle'}`}></i>
              </div>
              <div className='col'>
                <hr
                  className={`timeline_success ${shipment?.status === 'Delivered'
                    ? 'text-success '
                    : shipment?.status === 'Returned'
                      ? 'text-purple'
                      : shipment?.status === 'Out for Return'
                        ? 'text-orange'
                        : shipment?.status === 'Exception'
                          ? 'text-danger'
                          : 'active'
                    }`}
                />
              </div>

              <div className='col col-auto'>
                <i className={`fe fe-circle text-success fs-6 ${shipment?.status === 'Delivered' ? 'bg-success rounded-circle' : ''}`}></i>
              </div>
            </div>
            <div className='row d-flex align-items-center'>
              <div className='col col-auto'>{dayjs(shipment.create_date).local().format('LT')}</div>
              <div className='col text-center'>
                {shipment.create_date && shipment.pickup_date ? `${getDateTimeDiffernce(dayjs(shipment.pickup_date).local().format('lll'), dayjs(shipment.create_date).local().format('lll'))}` : ''}
              </div>
              <div className='col col-auto'>{shipment.pickup_date && dayjs(shipment.pickup_date).local().format('LT')}</div>
              <div className='col text-center'>
                {shipment.delivery_date && shipment.pickup_date
                  ? `${getDateTimeDiffernce(dayjs(shipment.delivery_date).local().format('lll'), dayjs(shipment.pickup_date).local().format('lll'))}`
                  : ''}
              </div>
              <div className='col col-auto'>{shipment.delivery_date && dayjs(shipment.delivery_date).local().format('LT')}</div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-xl-4'>
            <div className='card'>
              <div className='card-body'>
                <h5 className='text-muted mb-2'>SENDER</h5>
                <span className='fw-bold'>{shipment.ship_from?.shipper_name}</span>
                <div>{shipment.ship_from?.address1}</div>
                <div>{shipment.ship_from?.address2}</div>
                <div>
                  {shipment.ship_from?.city}, {shipment.ship_from?.state} {shipment.ship_from?.postal_code}
                </div>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col'>
                    <h5 className='text-muted mb-1'>{shipment.batch_number && shipment.batch_number > 0 ? 'REFERENCE NO.' : 'ORDER NO.'}</h5>
                    <span className='fw-bold'>{shipment.order_number}</span>
                  </div>
                  <div className='col'>
                    <h5 className='text-muted mb-1'>COURIER</h5>
                    <span className='fw-bold'>{shipment.carrier?.name}</span>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col'>
                    <h5 className='text-muted mb-1'>SERVICE TYPE</h5>
                    <span className='fw-bold'>
                      {shipment.carrier?.alias.toLowerCase() === 'usps' && uspsMailClass}
                      {(shipment?.ship_from?.shipper_name === 'UCSD Specialty Pharmacy (Kearny Mesa)' && shipment?.carrier?.alias === 'fedex') && fedExServiceType}
                      {shipment.carrier?.alias.toLowerCase() !== 'usps' && (shipment?.ship_from?.shipper_name !== 'UCSD Specialty Pharmacy (Kearny Mesa)' || shipment?.carrier?.alias !== 'fedex') && (
                        <>
                          {shipment?.service_type}
                          {isEditAccess && isCancel && <i className='fe fe-edit-2 ms-2' onClick={() => showServiceTypeModal(true)}></i>}
                        </>
                      )}
                      {shipment?.service_type?.toLowerCase() === `future` && <small className='d-block'>{moment(shipment.estimated_delivery_date).local().format('DD-MMM-YYYY')}</small>}
                    </span>
                  </div>
                  <div className='col'>
                    {shipment.weight && (
                      <div>
                        <h5 className='text-muted mb-1'>WEIGHT</h5>
                        <span className='fw-bold'>{shipment.weight} lbs</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col'>
                    <h5 className='text-muted mb-1'># Items</h5>
                    <span className='fw-bold'>
                      {!isEmpty(shipment?.parcels) && Object.values(shipment?.parcels[0])[0]?.length}{' '}
                      {isEditAccess && isCancel && !isEmpty(shipment?.parcels) && <i className='fe fe-edit-2 ms-2' onClick={() => showParcelModal(true)}></i>}
                      {shipment?.status?.toLowerCase() !== 'ready for pickup' && <i className='fe fe-eye ms-2' onClick={() => showParcelModal(true)}></i>}
                    </span>
                  </div>
                  {shipment?.delivery_timeslot?.length > 0 && (
                    <div className='col'>
                      {(shipment?.ship_from?.shipper_name !== 'UCSD Specialty Pharmacy (Kearny Mesa)' || shipment?.carrier?.alias !== 'fedex') && <>
                        <h5 className='text-muted mb-1'>TIME SLOT</h5>
                        <span className='fw-bold'>{shipment.delivery_timeslot}</span>
                      </>}
                    </div>
                  )}
                </div>

                <div className='row mt-4'>
                  <div className='col'>
                    <h5 className='text-muted mb-1'>ADULT SIGNATURE</h5>
                    <span className={updateAdultSign ? 'text-danger fw-bold' : 'fw-bold'} style={{ color: `${!updateAdultSign ? '#059250' : ''}` }} >
                      {signValue}
                      {(shipment?.status === 'Ready for Pickup' || shipment?.status === 'Out for Delivery') && isEditAccess && shipment.carrier?.alias.toLowerCase() !== 'usps' && shipment.carrier?.name !== 'FedEx' && (
                        <i className='fe fe-edit-2 ms-2 text-dark' onClick={() => setAdultSignatureModal(true)}></i>)}
                    </span>
                  </div>
                  {shipment.carrier?.alias.toLowerCase() !== 'usps' && (
                    <>
                      <div className='col'>
                        <h5 className='text-muted mb-1'>STORAGE TYPE</h5>
                        <span className='fw-bold'>{shipment.storage_type}
                          {shipment?.status === 'Ready for Pickup' && isEditAccess && shipment.carrier?.alias.toLowerCase() !== 'usps' && shipment.carrier?.name !== 'FedEx' && <i className='fe fe-edit-2 ms-2' onClick={() => showStorageTypeModal(true)} ></i>}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <div className='row mt-4'>
                  <div className='col'>
                    {shipment.ext_tracking_number && (
                      <div>
                        <h5 className='text-muted mb-1'>TRACKING NUMBER</h5>
                        <span className='fw-bold' style={{ cursor: 'pointer' }}>
                          {shipment?.carrier?.alias === 'fedex' || shipment?.carrier?.alias === 'USPS' ?
                            <a href={shipment?.tracking_url} target='_blank'>{shipment.ext_tracking_number}</a>
                            : shipment.ext_tracking_number
                          }
                        </span>
                      </div>
                    )}
                  </div>

                </div>
                <div className='row mt-4'>
                  <h5 className='text-muted mb-1'>INSTRUCTIONS</h5>
                  <span className='fw-bold'>
                    {shipment?.delivery_instructions}
                    {isEditAccess && isCancel && <i className='fe fe-edit-2 ms-2' onClick={() => showInstructionModal(true)}></i>}
                  </span>
                </div>
                <hr />

                {qrCode && (
                  <>
                    <div className='mt-4 text-center'>{<QRCode value={qrCode} />}</div>
                    <div className='d-flex justify-content-center align-items-end gap-3'>
                      {shipment.carrier?.name !== 'FedEx' && (
                        <>
                          <ReactToPrint
                            trigger={() => {
                              return (
                                <button className='btn btn-sm btn-light mt-3' id='printBtn'>
                                  <i className='fe fe-printer me-2' />
                                  Print Shipping Label
                                </button>
                              );
                            }}
                            content={() => componentRef.current}
                          />
                          <PrintShipment ref={componentRef} shipment={shipment} qrCode={qrCode} logo={props?.tenant_logo} />
                        </>
                      )}
                      {shipment.carrier?.name === 'FedEx' && (
                        <div>
                          {' '}
                          <a className='btn btn-sm btn-light mt-3' href={shipment.ext_shipping_label_url} target='_blank' rel='noopener noreferrer' id='printBtnFedex'>
                            <i className='fe fe-printer me-2' /> Print Shipping Label
                          </a>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='card d-none'>
              <div className='card-body'>
                <h3 className='card-title'>Shipment Details</h3>
                <ul className='list-group list-group-flush' key={0}>
                  <ListGroupItem key={1} label={shipment.batch_number && shipment.batch_number > 0 ? 'Reference No' : 'Order No'} value={shipment.order_number} />

                  <li className='list-group-item' key={2}>
                    <div className='row'>
                      <div className='col'>Service Type</div>
                      <div className='col-auto text-muted'>
                        {shipment.service_type} {isEditAccess && isCancel && <i className='fe fe-edit-2 ms-2' onClick={() => showServiceTypeModal(true)}></i>}
                      </div>
                    </div>
                  </li>
                  {shipment?.delivery_timeslot?.length > 0 && <ListGroupItem key={3} label='Time Slot' value={shipment.delivery_timeslot} />}
                  <li className='list-group-item' key={4}>
                    <div className='row'>
                      <div className='col'>Item Count</div>
                      <div className='col-auto text-muted'>
                        {!isEmpty(shipment?.parcels) && Object.values(shipment?.parcels[0])[0]?.length}{' '}
                        {isEditAccess && isCancel && !isEmpty(shipment?.parcels) && <i className='fe fe-edit-2 ms-2' onClick={() => showParcelModal(true)}></i>}
                      </div>
                    </div>
                  </li>
                  <ListGroupItem key={5} label='Storage Type' value={shipment.storage_type} />
                  <li className='list-group-item' key={6}>
                    <div className='row'>
                      <div className='col'>Instructions</div>
                      <div className='col-auto text-muted'>
                        {shipment.delivery_instructions}
                        {isEditAccess && isCancel && <i className='fe fe-edit-2 ms-2' onClick={() => showInstructionModal(true)}></i>}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-xl-4'>
            <div className='card d-none'>
              <div className='card-body'>
                <h3 className='card-title'>Timelines</h3>
                <ul className='list-group list-group-flush'>
                  <ListGroupItem label='Order Time' value={dayjs(shipment.create_date).local().format('lll')} />
                  <ListGroupItem label='Pickup Time' value={shipment.pickup_date && dayjs(shipment.pickup_date).local().format('lll')} />
                  <ListGroupItem label='Estimated Time' value={shipment.estimated_delivery_timeslot} />
                  <ListGroupItem label='Delivery Time' value={shipment.delivery_date && dayjs(shipment.delivery_date).local().format('lll')} />
                  {shipment.returned_date && (
                    <>
                      <ListGroupItem label='Return Pickup Time' value={shipment.out_for_return_date && dayjs(shipment.out_for_return_date).local().format('lll')} />
                      <ListGroupItem label='Return Time' value={shipment.returned_date && dayjs(shipment.returned_date).local().format('lll')} />
                    </>
                  )}
                  <ListGroupItem
                    label='Pickup to Delivery'
                    value={
                      shipment.delivery_date && shipment.pickup_date
                        ? `${getDateTimeDiffernce(dayjs(shipment.delivery_date).local().format('lll'), dayjs(shipment.pickup_date).local().format('lll'))}`
                        : ''
                    }
                  />
                  <ListGroupItem
                    label='Turn Around Time'
                    value={
                      shipment.delivery_date && shipment.create_date
                        ? `${getDateTimeDiffernce(dayjs(shipment?.delivery_date).local().format('lll'), dayjs(shipment?.create_date).local().format('lll'))}`
                        : ''
                    }
                  />
                </ul>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                {shipment?.status === 'Out for Delivery' && (
                  <>
                    {(shipmentTracking?.route_sequence !== 99 || shipmentTracking?.route_sequence !== 0) && (
                      <div>
                        {shipmentTracking?.route_sequence === 1 ? (
                          <h3 className='mt-2 mb-2'>The driver is on the way to your location </h3>
                        ) : shipmentTracking?.route_sequence === 2 ? (
                          <h3 className='mt-2 mb-2'>Your driver is 1 stop away</h3>
                        ) : shipmentTracking?.route_sequence !== 99 && shipmentTracking?.route_sequence > 1 ? (
                          <h3 className='mt-2 mb-2'>{`Your driver is ${shipmentTracking?.route_sequence - 1} Stops away`}</h3>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    <div>
                      {shipmentTracking?.route_duration > 0 && (
                        <h2 className='alert-heading mb-2 mt-2'>
                          {Math.floor(shipmentTracking?.route_duration / 3600) < 5 ? getEstimatedTimeslot(shipmentTracking?.route_duration) : 'Standby for new ETA'}
                        </h2>
                      )}
                    </div>
                  </>
                )}
                <ul id='progressbar' className='mt-4 mb-0'>
                  <li id='ship_from' className='active'></li>
                  {trackingUrl?.length > 0 ? (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={`${trackingUrl}/${shipment.id}`} target='_blank'>
                      <li id='driver' className={shipment?.status === 'Exception' ? 'inactive' : shipment?.status !== 'Ready for Pickup' && shipment?.status !== 'Exception' ? 'active' : ''}></li>
                    </a>
                  ) : (
                    <li id='driver' className={shipment?.status === 'Exception' ? 'inactive' : shipment?.status !== 'Ready for Pickup' && shipment?.status !== 'Exception' ? 'active' : ''}></li>
                  )}
                  <li id='ship_to' className={shipment?.status === 'Delivered' || shipment?.status === 'Returned' ? 'active' : shipment?.status === 'Exception' ? 'inactive' : ''}></li>
                </ul>
                {shipment?.distance && (
                  <a className='mt-3 ms-2' href={`https://maps.google.com?saddr=${location?.source}&daddr=${location?.destination}&directionsmode=driving&dir_action=navigate`} target=' _blank'>
                    {` Distance: ${Number(shipment.distance)} mi`}
                  </a>
                )}
              </div>
            </div>
            <div className='card'>
              <div className='card-header'>
                <h4 className={`card-header-title text-center h3 ${shipment?.status_colour}`}>
                  {shipment?.status === 'Delivered'
                    ? `${shipment?.status}: ${shipment.delivery_date && shipment.create_date
                      ? `${getDateTimeDiffernce(dayjs(shipment?.delivery_date).local().format('lll'), dayjs(shipment?.create_date).local().format('lll'))}`
                      : ''
                    }`
                    : shipment?.status === 'Exception'
                      ? 'Cancelled'
                      : shipment?.status}
                </h4>
                {isEditAccess && <i className='fe fe-edit' onClick={() => toggleModal('history')}></i>}
              </div>

              <div className='card-body'>
                <ul className='list-group list-group-flush'>
                  {historyList &&
                    historyList?.map((status, index) => {
                      return (
                        <li className='list-group-item' key={index}>
                          <div className='row'>
                            <div className='col-auto'>
                              <Avatar image={status.user_photo_url} />
                            </div>
                            <div className='col'>
                              {status.user_name} <i className='fe fe-arrow-right' /> {status?.status === 'Exception' ? 'Cancelled' : status?.status}
                              <div className='small text-muted'>{status.description}</div>
                              <div className='small text-muted'>{toLocalDate(status.create_date, 'lll')}</div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-xl-4'>
            {!isEmpty(feedbackList) && (
              <>
                <div className='card'>
                  <div className='card-body'>
                    <h3 className='card-title text-center'>Feedback</h3>
                    <div className='mt-4 row text-center'>
                      <div className='col col-6 border-3 border-end border-light'>
                        <h5 className='text-muted'>DELIVERY</h5>
                        <h1 className='display-1 text-warning'>
                          {feedbackList?.rating}
                          <span className='text-muted h2'>{`/ 5`}</span>
                        </h1>
                        <h4 className='text-muted'>STARS</h4>
                      </div>
                      <div className='col col-6'>
                        <h5 className='text-muted'>NPS</h5>
                        <h1 className={`display-1 ${feedbackList?.nps_score}`}>
                          {feedbackList?.nps}
                          <span className='text-muted h2'>{`/ 10`}</span>
                        </h1>
                        <h4 className='text-muted'>POINTS</h4>
                      </div>
                    </div>
                    <div className='border border-light mt-3'></div>
                    <div className='mt-4 text-center'>{feedbackList.comment}</div>
                    <div className='small text-center text-muted mt-2'>{toLocalDate(feedbackList.create_date, 'LLL')}</div>
                  </div>
                </div>
              </>
            )}
            <div className='card'>
              <div className='card-body'>
                <h3 className='card-title text-center'>Proof of delivery</h3>
                <ul className='list-group list-group-flush'>
                  <ListGroupItem label='Signed By' value={podSignature?.name} />
                  <ListGroupItem label='Relationship' value={podSignature?.relation_ship} />
                  {deliveryMessage?.length > 0 && <ListGroupItem label='Delivery Message' value={deliveryMessage} />}
                </ul>
                {deliveryProofs?.length > 0 && <ImageGallery items={deliveryProofs} showBullets={false} showThumbnails={false} lazyLoad={true} showPlayButton={false} />}

                <div className='text-center mb-2'>{isVerifiedWithOtp && <div className='header-title'></div>}</div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {isCancel && isEditAccess && (
          <button type='button' className='me-2 btn btn-outline-danger' onClick={() => showCancelModal(true)}>
            Cancel
          </button>
        )}
      </div>

      <Modal show={modalRecipient} onHide={() => showRecipientModal(false)} backdrop='static'>
        <Modal.Body>
          <Modal.Title>
            <div className='d-flex justify-content-between'>
              <h2>Recipient</h2>
              <button type='button' disabled={loading} className='btn btn-white' onClick={resetAddress}>
                Cancel
              </button>
            </div>
          </Modal.Title>
          <hr />
          <Formik enableReinitialize={true} initialValues={recipientDefaultvalues} validationSchema={ship_to_validations} onSubmit={updateRecipient}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <label className='mb-2'>Company Name</label>
                  <input className='form-control' name='company_name' placeholder='e.g. ABC Company' value={values.company_name} onChange={handleChange} isInvalid={!!errors.company_name} />
                </div>

                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>
                        Contact Name<span className='text-danger'>*</span>
                      </label>
                      <input className='form-control' name='contact_name' placeholder='e.g. John Doe' value={values.contact_name} onChange={handleChange} isInvalid={!!errors.contact_name} />
                      {errors.contact_name && <div className='text-danger mt-2 ms-1 h5'>{errors.contact_name}</div>}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label className='mb-2'>C/O</label>
                      <input
                        className='form-control'
                        name='secondary_contact_name'
                        placeholder='e.g. Jane Doe'
                        value={values.secondary_contact_name}
                        onChange={handleChange}
                        isInvalid={!!errors.secondary_contact_name}
                      />
                      {errors.secondary_contact_name && <div className='text-danger mt-2 ms-1 h5'>{errors.secondary_contact_name}</div>}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group'>
                    <label className='mb-2'>Email</label>
                    <input className='form-control' type='email' name='email' placeholder='e.g. john@phoxhealth.com' value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                    {errors.email && <div className='text-danger mt-2 ms-1 h5'>{errors.email}</div>}
                  </div>
                  <div className='form-group'>
                    <label className='mb-2'>Phone</label>
                    <input className='form-control' type='phone' name='phone' placeholder='' value={values.phone} onChange={handleChange} isInvalid={!!errors.phone} />
                    {errors.phone && <div className='text-danger mt-2 ms-1 h5'>{errors.phone}</div>}
                  </div>
                </div>

                <hr />

                <div className='row'>
                  <div className='form-group'>
                    <label className='mb-2'>
                      {' '}
                      Address Line 1<span className='text-danger'>*</span>
                    </label>
                    <PlacesAutocomplete name='address1' value={values.address1} onChange={autoHandleChange} onSelect={handleAddressSelect}>
                      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                        <div className='search-input-container col-12 p-0 mb-4'>
                          <input
                            {...getInputProps({
                              placeholder: 'e.g. 100 W Main St.',
                              className: 'form-control business-form',
                            })}
                            isInvalid={!!errors.address1}
                          />
                          {errors.address1 && <div className='text-danger mt-2 ms-1 h5'>{errors.address1}</div>}
                          <div className='autocomplete-container bg-light'>
                            {suggestions.map((suggestion) => {
                              const className = 'suggestion-item';
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className='row'>
                  <div className='col col-6'>
                    <div className='form-group'>
                      <label className='mb-2'>Address Line 2</label>
                      <input type='text' name='address2' className='form-control' placeholder='e.g. Suite 102' value={values.address2} onChange={handleChange} isInvalid={!!errors.address2} />
                      {errors.address2 && <div className='text-danger mt-2 ms-1 h5'>{errors.address2}</div>}
                    </div>
                  </div>
                  <div className='col col-6'>
                    <div className='form-group'>
                      <label className='mb-2'>
                        {' '}
                        City<span className='text-danger'>*</span>
                      </label>
                      <input type='text' className='form-control' name='city' placeholder='e.g. Seattle' value={values.city} onChange={handleChange} isInvalid={!!errors.city} />
                      {errors.city && <div className='text-danger mt-2 ms-1 h5'>{errors.city}</div>}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col col-6'>
                    <div className='form-group'>
                      <label className='mb-2'>
                        {' '}
                        State<span className='text-danger'>*</span>
                      </label>
                      <input type='text' className='form-control' name='state' placeholder='e.g. WA' value={values.state} onChange={handleChange} isInvalid={!!errors.state} />
                      {errors.state && <div className='text-danger mt-2 ms-1 h5'>{errors.state}</div>}
                    </div>
                  </div>
                  <div className='col col-6'>
                    <div className='form-group'>
                      <label className='mb-2'>
                        Postal Code<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        maxLength='5'
                        className='form-control'
                        name='postal_code'
                        placeholder='e.g. 21044'
                        value={values.postal_code}
                        onChange={handleChange}
                        isInvalid={!!errors.postal_code}
                      />
                      {errors.postal_code && <div className='text-danger mt-2 ms-1 h5'>{errors.postal_code}</div>}
                    </div>
                  </div>

                  {!isEmpty(distance) && (
                    <span className={Number(distance?.shipment_miles) < Number(distance?.shipper_miles) ? 'text-success' : 'text-danger'}>
                      {' '}
                      <i className='fe fe-info' />
                      &nbsp;{`Delivery distance is ${distance?.shipment_miles} miles.`}
                    </span>
                  )}
                  <hr />
                  <div className='d-flex justify-content-end'>
                    <button type='button' disabled={loading} onClick={resetAddress} className='mt-4 btn btn-white'>
                      Cancel
                    </button>
                    <button type='submit' className='mt-4 btn btn-primary ms-2' disabled={!phoneNumber.is_valid || loading}>
                      Update
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={modalStatus} onClose={() => showStatusModal(false)}>
        <div className='modal-content'>
          <div className='modal-header h1'>Update Status</div>
          <Formik initialValues={currentShipmentStatus} onSubmit={updateStatus}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='form-group'>
                    <select className='form-control' as='select' name='status' custom value={values.status} onChange={handleChange}>
                      <option value='Ready for Pickup'>Ready for Pickup</option>
                      {isCancel && <option value='Exception'>Cancel</option>}
                    </select>
                  </div>
                  <div className='form-group'>
                    <label className='mb-2'>Description / Reason</label>
                    <textarea className='form-control' rows='5' name='description' value={values.description} onChange={handleChange} />
                  </div>
                </div>
                <div className='modal-footer d-flex justify-content-end'>
                  <button type='submit' className='btn btn-primary' disabled={values?.status === shipment?.status || loading}>
                    Update
                  </button>
                  <button
                    type='button'
                    disabled={loading}
                    onClick={() => {
                      showStatusModal(false);
                    }}
                    className='ms-2 btn btn-white'
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>

      <Modal show={modalHistory} onClose={() => showHistoryModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'>Description</Modal.Title>
          <hr />
          <Formik initialValues={currentShipmentStatus} onSubmit={updateHistory}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <select className='form-control' as='select' name='status' custom value={values.status} disabled>
                    <option value='Ready for Pickup'>Ready for Pickup</option>
                    <option value='Out for Delivery'>Out for Delivery</option>
                    <option value='Delivered'>Delivered</option>
                    <option value='Exception'>Cancelled</option>
                    <option value='Out for Return'>Out for Return</option>
                    <option value='Returned'>Returned</option>
                  </select>
                </div>
                <div className='form-group'>
                  <label className='mb-2'>Description / Reason</label>
                  <textarea className='form-control' rows='5' name='description' value={values.description} onChange={handleChange} />
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    disabled={loading}
                    type='button'
                    onClick={() => {
                      showHistoryModal(false);
                    }}
                    className='btn btn-white'
                  >
                    Cancel
                  </button>
                  <button disabled={values.description.length <= 0 || loading} type='submit' className='btn btn-primary ms-2 '>
                    Update
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={modalCancel} onClose={() => showCancelModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'>Cancel Shipment #{shipment?.number}?</Modal.Title>

          <Formik initialValues={currentShipmentStatus} onSubmit={onCancelShipment}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <label className='mb-2'>Description / Reason</label>
                  <textarea className='form-control' rows='5' name='description' placeholder='e.g. Not available at home ' value={values.description} onChange={handleChange} />
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    disabled={loading}
                    type='button'
                    className='btn btn-white'
                    onClick={() => {
                      showCancelModal(false);
                    }}
                  >
                    No
                  </button>
                  <button type='submit' className='btn btn-danger ms-2' disabled={loading}>
                    Yes, Cancel
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={modalServiceType} onClose={() => showServiceTypeModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'> Update Service Type</Modal.Title>
          <hr />
          <Formik enableReinitialize={true} initialValues={shipment} onSubmit={updateServiceType}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <select className='form-control' name='service_type' custom value={values.service_type} onChange={handleChange}>
                    {serviceType && serviceType.length > 0 ? (
                      serviceType?.map((service, index) => {
                        return (
                          <>
                            <option key={index} value={service.name}>
                              {service.name}
                            </option>
                          </>
                        );
                      })
                    ) : (
                      <option value='Same Day'>Same Day</option>
                    )}
                  </select>
                  {values.service_type.toLowerCase() === 'future' && (
                    <DatePicker
                      selected={new Date(futureDeliveryDate)}
                      onChange={(date) => setFutureDeliveryDate(moment(date).format('MMM DD, YYYY'))}
                      className='my-3 form-control'
                      minDate={new Date()}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    onClick={() => {
                      showServiceTypeModal(false);
                    }}
                    className='btn btn-white'
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-primary ms-2 '>
                    Update
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal show={modalstorageType} onClose={() => showStorageTypeModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'> Update Storage Type</Modal.Title>
          <hr />
          <Formik enableReinitialize={true} initialValues={shipment} onSubmit={updateStorageType}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <select className='form-control' name='storage_type' custom value={values.storage_type} onChange={handleChange}>
                    {storageTypesList && storageTypesList.length > 0 && (
                      storageTypesList?.map((storage, index) => {
                        return (
                          <option key={index} value={storage.name}  >
                            {storage.name}
                          </option>
                        );
                      })
                    )}
                  </select>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    onClick={() => {
                      showStorageTypeModal(false);
                    }}
                    className='btn btn-white'
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-primary ms-2 '>
                    Update
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={modalInstructions} onClose={() => showInstructionModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'>Update Delivery Instructions</Modal.Title>
          <hr />
          <Formik enableReinitialize={true} initialValues={shipment} onSubmit={updateSpecialInstructions}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <textarea className='form-control' rows='2' name='special_instruction' placeholder='e.g. Requires cold storage' value={values.special_instruction} onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    onClick={() => {
                      showInstructionModal(false);
                    }}
                    className='btn btn-light'
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-primary ms-2'>
                    Update
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal size={'lg'} show={modalParcel} onClose={() => showParcelModal(false)}>
        <div className='modal-content p-4'>
          <div className='d-flex justify-content-between'>
            {shipment?.status?.toLowerCase() === 'ready for pickup' ?
              <>
                <p className='mb-2 h2'> Update Parcel</p>
                <button
                  type='button'
                  className='btn btn-sm btn-primary ms-2'
                  onClick={(e) => {
                    handleAddRecord(e);
                  }}
                >
                  Add
                </button>
              </>
              :
              <>
                <p className='mb-2 h2'> View Parcel</p>
              </>}
          </div>
          <table id='datatable' className='table table-lg table-borderless table-thead-bordered table-nowrap card-table dataTable no-footer mt-4'>
            <thead>
              <tr>
                <th>Reference Number</th>
                <th>
                  Product Type
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className='list font-size-base'>
              {orderList &&
                orderList?.map((data, index) => {
                  return (
                    <tr key={`${data}-${index}`}>
                      <td>
                        <div className='form-group mb-0'>
                          <input
                            className='form-control'
                            key={index}
                            id='index'
                            type='text'
                            name={`order_number-${index}`}
                            placeholder='e.g. 1112-12322'
                            value={data.order_number}
                            onChange={(e) => handleChangeRecord(index, e, 'orderNumber')}
                            disabled={shipment?.status?.toLowerCase() !== 'ready for pickup'}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='form-group mb-0'>
                          <select
                            className='form-control'
                            key={index}
                            id='index'
                            name={`product_type-${index}`}
                            custom
                            value={data.product_type}
                            onChange={(e) => handleChangeRecord(index, e, 'productType')}
                            disabled={shipment?.status?.toLowerCase() !== 'ready for pickup'}
                          >
                            {productTypes &&
                              productTypes?.map((type, index) => {
                                return (
                                  <option key={index} value={type.name}>
                                    {type.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </td>
                      <td>
                        {shipment?.status?.toLowerCase() === 'ready for pickup' ?
                          <button className='btn btn-sm btn-white' type='button'>
                            <i
                              className='fe fe-trash'
                              onClick={() => {
                                handleRemoveRecord(index);
                              }}
                            ></i>
                          </button> : <></>}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className='d-flex justify-content-end'>
            <button disabled={loading} type='button' onClick={ResetParcelCount} className='btn btn-white'>
              Cancel
            </button>
            {shipment?.status?.toLowerCase() === 'ready for pickup' ?
              <button disabled={loading} type='submit' className='btn btn-primary ms-2' onClick={UpdateParcelCount}>
                Update
              </button>
              : <></>
            }
          </div>
        </div>
      </Modal>

      <Modal show={adultSignatureModal} onClose={() => setAdultSignatureModal(false)}>
        <Modal.Body>
          <Modal.Title className='h1'>Update Adult Signature </Modal.Title>
          <hr />
          <Formik initialValues={currentShipmentStatus} onSubmit={() => updateAdultSignature()}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col col-sm-12 col-md-4 mt-3'>
                    <div className='form-check form-switch'>
                      <input className='form-check-input' id='adultSignatureCheckbox' type='checkbox' name='adult_signature' value={updateAdultSign} checked={updateAdultSign} onChange={() => updateSignature()} />
                      <label className='form-check-label' htmlFor='adultSignatureCheckbox'>
                        Adult Signature Required
                      </label>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    disabled={loading}
                    type='button'
                    onClick={() => {
                      setAdultSignatureModal(false);
                      updateSignature()
                    }}
                    className='btn btn-white'
                  >
                    Cancel
                  </button>
                  <button className='btn btn-primary ms-2'  >
                    Update
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {shipmentFedexModal && shipment.carrier?.name === 'FedEx' && (
        <Modal show={shipmentFedexModal} onClose={() => setShipmentFedexModal(false)}>
          <div className='modal-content'>
            <div className='modal-header h1'>Standby for FedEx label...</div>
            <div className='container d-flex justify-content-center'>
              <img src='/img/logos/fedex.png' alt='fedex' height={200} />
            </div>
          </div>
        </Modal>
      )}
    </Spinner>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
    tenant_logo: state.tenant_logo,
  };
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(connect(mapStateToProps)(ShipmentDetail));
