import moment from 'moment';
import FileSaver from 'file-saver';
const { parse } = require('json2csv');

export const downloadShipmentExcelReport = (shipments, date) => {
  const fileName = `Shipments_Report (${moment(date.fromDate).format('MMMM Do YYYY')} - ${moment(date.toDate).format('MMMM Do YYYY')})`;
  let saveAs = require('file-saver');
  let xl = require('excel4node');
  let wb = new xl.Workbook();
  let tableHeaderStyle = wb.createStyle({ font: { size: 11, bold: true }, fill: { type: 'pattern', patternType: 'solid', fgColor: '#edf2f9' } });
  let tableItemStyle = wb.createStyle({ font: { size: 11 } });
  if (shipments?.length > 0) {
    let ws = wb.addWorksheet('Sheet1');
    ws.column(1).setWidth(15);
    ws.column(2).setWidth(20);
    ws.column(3).setWidth(25);
    ws.column(4).setWidth(25);
    ws.column(5).setWidth(25);
    ws.column(6).setWidth(25);
    ws.column(7).setWidth(35);
    ws.column(8).setWidth(25);
    ws.column(9).setWidth(25);
    ws.column(10).setWidth(25);
    ws.column(11).setWidth(25);
    ws.column(12).setWidth(25);
    ws.column(13).setWidth(25);
    ws.column(14).setWidth(25);
    ws.column(15).setWidth(25);
    ws.column(16).setWidth(25);
    ws.column(17).setWidth(25);
    ws.column(18).setWidth(25);
    ws.column(19).setWidth(25);
    ws.column(20).setWidth(45);
    ws.column(21).setWidth(25);
    ws.column(22).setWidth(20);
    ws.column(23).setWidth(25);
    ws.column(24).setWidth(20);
    ws.column(25).setWidth(20);
    ws.column(26).setWidth(20);
    ws.column(27).setWidth(40);
    ws.column(28).setWidth(20);
    ws.column(29).setWidth(20);
    ws.column(30).setWidth(40);
    //Headers
    ws.cell(1, 1).string(`Shipment No`).style(tableHeaderStyle);
    ws.cell(1, 2).string(`Reference No`).style(tableHeaderStyle);
    ws.cell(1, 3).string(`Order No`).style(tableHeaderStyle);
    ws.cell(1, 4).string(`Time Zone`).style(tableHeaderStyle);
    ws.cell(1, 5).string(`Order Date`).style(tableHeaderStyle)
    ws.cell(1, 6).string(`Shipment Date`).style(tableHeaderStyle);;
    ws.cell(1, 7).string(`Special Instruction`).style(tableHeaderStyle);
    ws.cell(1, 8).string(`Adult Signature`).style(tableHeaderStyle);
    ws.cell(1, 9).string(`Refrigeration`).style(tableHeaderStyle);
    ws.cell(1, 10).string(`Service Type`).style(tableHeaderStyle);
    ws.cell(1, 11).string(`Parcel Count`).style(tableHeaderStyle);
    ws.cell(1, 12).string(`Status`).style(tableHeaderStyle);
    ws.cell(1, 13).string(`Order Pickup`).style(tableHeaderStyle);
    ws.cell(1, 14).string(`Order Delivered`).style(tableHeaderStyle);
    ws.cell(1, 15).string(`Order Out for Return`).style(tableHeaderStyle);
    ws.cell(1, 16).string(`Order Returned`).style(tableHeaderStyle);
    ws.cell(1, 17).string(`Full Turnaround Time (mins)`).style(tableHeaderStyle);
    ws.cell(1, 18).string(`Pick-up to Delivery Time (mins)`).style(tableHeaderStyle);
    ws.cell(1, 19).string(`Shipper Name`).style(tableHeaderStyle);
    ws.cell(1, 20).string(`Shipper Email`).style(tableHeaderStyle);
    ws.cell(1, 21).string(`Patient Name`).style(tableHeaderStyle);
    ws.cell(1, 22).string(`Patient Address`).style(tableHeaderStyle);
    ws.cell(1, 23).string(`Courier Company`).style(tableHeaderStyle);
    ws.cell(1, 24).string(`Driver Name`).style(tableHeaderStyle);
    ws.cell(1, 25).string(`Courier Email`).style(tableHeaderStyle);
    ws.cell(1, 26).string(`Delivery Distance`).style(tableHeaderStyle);
    ws.cell(1, 27).string(`Proof Of Delivery`).style(tableHeaderStyle);
    ws.cell(1, 28).string(`Rating`).style(tableHeaderStyle);
    ws.cell(1, 29).string(`NPS Score`).style(tableHeaderStyle);
    ws.cell(1, 30).string(`Comment`).style(tableHeaderStyle);
    ws.cell(1, 31).string(`Courier Cost`).style(tableHeaderStyle);
    ws.cell(1, 32).string(`Phox Processing Fee`).style(tableHeaderStyle);

    let row = 1;
    shipments.forEach((shipment) => {
      row++;
      let driverName = '';
      let minutes = 0;
      if (shipment?.driver?.name) {
        let name = shipment?.driver?.name.split(' ');
        driverName = name[1] ? `${name[0]} ${name[1].charAt(0)}.` : `${name[0]}.`;
      }
      if (shipment?.status.toLowerCase() === "delivered") {
        minutes = moment(shipment?.delivery_date).diff(moment(shipment?.pickup_date), 'minute') || 0 ;
      }
      let deliveryfee = shipment?.billing?.detailed_charges?.length > 0 ? shipment?.billing?.detailed_charges[0]?.amount : '';
      let platformfee = shipment?.billing?.detailed_charges?.length > 0 ? shipment?.billing?.detailed_charges[1]?.amount : '';
      let CourierCost = (shipment?.shipper_name.includes('Elizabeth')) && (shipment?.service_type.toLowerCase() === "same day") ? '9.5' : (shipment?.shipper_name.includes('Elizabeth')) && (shipment?.service_type.toLowerCase() !== "same day") ? '9' : ''
      let PhoxProcessingfee = (shipment?.shipper_name.includes('Elizabeth')) ? 2 : '';

      ws.cell(row, 1).number(shipment?.number).style(tableItemStyle);
      ws.cell(row, 2)
        .number(shipment?.batch_number > 0 ? shipment?.batch_number : shipment?.number)
        .style(tableItemStyle);
      ws.cell(row, 3).string(shipment?.order_number).style(tableItemStyle);
      ws.cell(row, 4)
        .string(shipment?.ship_from?.timezone?.timezone_alias || 'PST')
        .style(tableItemStyle);
      ws.cell(row, 5).string(shipment?.create_date).style(tableItemStyle);
      ws.cell(row, 6).string(shipment.estimated_delivery_date?.length > 0 ? moment(shipment.estimated_delivery_date).local().format('MMM DD YYYY') : '').style(tableItemStyle);
      ws.cell(row, 7)
        .string(shipment?.special_instruction?.length > 0 ? shipment?.special_instruction : '')
        .style(tableItemStyle);
      ws.cell(row, 8)
        .string(shipment?.signature_required === true ? 'Yes' : 'No')
        .style(tableItemStyle);
      ws.cell(row, 9)
        .string(shipment?.refrigeration_required === true ? 'Yes' : 'No')
        .style(tableItemStyle);
      ws.cell(row, 10).string(shipment?.service_type).style(tableItemStyle);
      ws.cell(row, 11).number(shipment?.parcel_count).style(tableItemStyle);
      ws.cell(row, 12)
        .string(shipment?.status === 'Exception' ? 'Cancelled' : shipment?.status)
        .style(tableItemStyle);
      ws.cell(row, 13).string(shipment?.pickup_date).style(tableItemStyle);
      ws.cell(row, 14).string(shipment?.delivery_date).style(tableItemStyle);
      ws.cell(row, 15).string(shipment?.out_for_return_date).style(tableItemStyle);
      ws.cell(row, 16).string(shipment?.returned_date).style(tableItemStyle);
      if (shipment?.TAT === null) {
        ws.cell(row, 17).string('').style(tableItemStyle);
      } else {
        ws.cell(row, 17).number(shipment?.TAT).style(tableItemStyle);
      }
      ws.cell(row, 18).number(minutes).style(tableItemStyle);
      ws.cell(row, 19).string(shipment.shipper_name).style(tableItemStyle);
      ws.cell(row, 20).string(shipment?.ship_from?.email).style(tableItemStyle);
      ws.cell(row, 21).string(`${shipment.ship_to.contact_name}`);
      ws.cell(row, 22).string(`${shipment.ship_to.address1} ${shipment.ship_to.address2},${shipment.ship_to.city}, ${shipment.ship_to.state} ${shipment.ship_to.postal_code}`).style(tableItemStyle);
      ws.cell(row, 23).string(shipment?.carrier?.name).style(tableItemStyle);
      ws.cell(row, 24).string(driverName).style(tableItemStyle);
      ws.cell(row, 25).string(shipment?.carrier?.email).style(tableItemStyle);
      ws.cell(row, 26).number(shipment?.distance).style(tableItemStyle);
      ws.cell(row, 27).string(shipment?.delivery_proof).style(tableItemStyle);
      if (shipment.rating === null) {
        ws.cell(row, 28).string('').style(tableItemStyle);
      } else {
        ws.cell(row, 28).number(shipment?.rating).style(tableItemStyle);
      }
      if (shipment.nps === null) {
        ws.cell(row, 29).string('').style(tableItemStyle);
      } else {
        ws.cell(row, 29).number(shipment?.nps).style(tableItemStyle);
      }
      ws.cell(row, 30).string(shipment.comment).style(tableItemStyle);
      ws.cell(row, 31).string(`${CourierCost ? CourierCost : deliveryfee}`).style(tableItemStyle);
      ws.cell(row, 32).string(`${PhoxProcessingfee ? PhoxProcessingfee : platformfee}`).style(tableItemStyle);
    });
  }
  wb.writeToBuffer().then(function (buffer) {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
  });
};

export const downloadShipmentCsvReport = (shipments, date) => {

  const fileName = `Shipments_Report (${moment(date.fromDate).format('MMMM Do YYYY')} - ${moment(date.toDate).format('MMMM Do YYYY')})`;
  if (shipments?.length > 0) {
    const fields = ['Shipment_No', 'Reference_No', 'Order_No', 'Time_Zone', 'Order_Date', 'Shipment_Date', 'Special_Instruction', 'Adult_Signature', 'Refrigeration', 'Service_Type', "Parcel_Count", 'Status', 'Order_Pickup', 'Order_Delivered', 'Order_Out_for_Return', 'Order_Returned', 'Full Turnaround Time (mins)', 'Shipper_Name', 'Shipper_Email', 'Patient_Name', 'Patient_Address', 'Courier_Company', 'Driver_Name', 'Courier_Email', 'Delivery_Distance', 'Proof_Of_Delivery', 'Rating', 'NPS_Score', 'Comment', 'Courier Cost', 'Phox Processing Fee'];
    const shipmentData = [];
    shipments.forEach((shipment) => {
      let driverName = '';
      let minutes = 0;
      if (shipment?.driver?.name) {
        let name = shipment?.driver?.name.split(' ');
        driverName = name[1] ? `${name[0]} ${name[1].charAt(0)}.` : `${name[0]}.`;
      }
      if (shipment?.status.toLowerCase() === "delivered") {
        minutes = moment(shipment?.delivery_date).diff(moment(shipment?.pickup_date), 'minute');
      }

      let deliveryfee = shipment?.billing?.detailed_charges?.length > 0 ? shipment?.billing?.detailed_charges[0]?.amount : '';
      let platformfee = shipment?.billing?.detailed_charges?.length > 0 ? shipment?.billing?.detailed_charges[1]?.amount : '';
      let CourierCost = (shipment?.shipper_name.includes('Elizabeth')) && (shipment?.service_type.toLowerCase() === "same day") ? '9.5' : (shipment?.shipper_name.includes('Elizabeth')) && (shipment?.service_type.toLowerCase() !== "same day") ? '9' : ''
      let PhoxProcessingfee = (shipment?.shipper_name.includes('Elizabeth')) ? 2 : '';


      shipmentData.push({
        Shipment_No: shipment?.number,
        Reference_No: shipment?.batch_number > 0 ? shipment?.batch_number : shipment?.number,
        Order_No: shipment?.order_number,
        Time_Zone: shipment?.ship_from?.timezone?.timezone_alias || 'PST',
        Order_Date: shipment?.create_date,
        Shipment_Date: shipment.estimated_delivery_date?.length > 0 ? moment(shipment.estimated_delivery_date).local().format('MMMM D YYYY') : '',
        Special_Instruction: shipment?.special_instruction?.length > 0 ? shipment?.special_instruction : '',
        Adult_Signature: shipment?.signature_required === true ? 'Yes' : 'No',
        Refrigeration: shipment?.refrigeration_required === true ? 'Yes' : 'No',
        Service_Type: shipment?.service_type,
        Parcel_Count: shipment.parcel_count,
        Status: shipment?.status === 'Exception' ? 'Cancelled' : shipment?.status,
        Order_Pickup: shipment?.pickup_date,
        Order_Delivered: shipment?.delivery_date,
        Order_Out_for_Return: shipment?.out_for_return_date,
        Order_Returned: shipment?.returned_date,
        'Full Turnaround Time (mins)': shipment?.TAT,
        'Pick-up to Delivery Time (mins)': minutes,
        Shipper_Name: shipment.shipper_name,
        Shipper_Email: shipment?.ship_from?.email,
        Patient_Name: `${shipment.ship_to.contact_name}`,
        Patient_Address: `${shipment.ship_to.address1} ${shipment.ship_to.address2},${shipment.ship_to.city}, ${shipment.ship_to.state} ${shipment.ship_to.postal_code}`,
        Courier_Company: shipment?.carrier?.name,
        Driver_Name: driverName,
        Courier_Email: shipment?.carrier?.email,
        Delivery_Distance: shipment?.distance || 0,
        Proof_Of_Delivery: shipment?.delivery_proof,
        Rating: shipment.rating === null ? '' : shipment.rating,
        NPS_Score: shipment.nps,
        Comment: shipment.comment,
        Courier_Cost: CourierCost ? CourierCost : deliveryfee,
        Phox_Processing_Fee: PhoxProcessingfee ? PhoxProcessingfee : platformfee,
      });
    });
    // generate CSV
    const csv = parse(shipmentData, fields);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, `${fileName}.csv`);
  }
};
