import React from 'react';
import moment from 'moment';
let QRCode = require('qrcode.react');

const PrintShipment = React.forwardRef((props, ref) => {
    const shipment = props?.shipment;
    const qrCode = props?.qrCode;

    return (
        <div className='container print-container print-source py-5' ref={ref}>
            {shipment?.ext_shipping_label_url &&
                <img id="frame" src={shipment.ext_shipping_label_url} alt='usps label' className='' style={{ width: '600px', height: '400px' }} />
            }
            {!shipment?.ext_shipping_label_url &&
                <div className='border border-secondary border-2 rounded px-3'>
                    <div className='row border-bottom border-secondary border-2 py-2'>
                        <div className='col-7'>
                            {
                                shipment?.ship_from && (
                                    <div>
                                        <div className='font-weight-bold'>{shipment?.ship_from?.alias}</div>
                                        <div className='small'>{shipment?.ship_from?.address1}</div>
                                        <div className='small'>{shipment?.ship_from?.address2}</div>
                                        <div className='small'>
                                            {shipment?.ship_from?.city},{` `}{shipment?.ship_from?.state},{` `}{shipment?.ship_from?.postal_code}
                                        </div>
                                        <div className='small'>{shipment?.ship_from?.phone}</div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='col d-flex flex-column align-items-end'>
                            <img src={'/img/logo.png'} className='img-fluid' alt='logo' />
                            <div className='display-3 mt-3'>{`#${shipment?.number}`}</div>
                        </div>
                    </div>
                    <div className='row border-bottom border-secondary border-2 text-center py-2'>
                        <div className='col'>
                            Shipment Date: {moment(shipment.estimated_delivery_date).local().format('DD-MMM-YYYY')}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-7'>
                            {
                                shipment.ship_to && (
                                    <div className='text-left mt-2'>
                                        <div className='font-weight-bold'>
                                            {shipment?.ship_to?.secondary_contact_name
                                                ? `${shipment?.ship_to?.contact_name} C/O ${shipment?.ship_to?.secondary_contact_name}`
                                                : shipment?.ship_to?.contact_name}
                                        </div>
                                        {shipment?.ship_to?.company_name && <div>{shipment?.ship_to?.company_name}</div>}
                                        <div className='small'>{shipment?.ship_to?.address1}</div>
                                        <div className='small'>{shipment?.ship_to?.address2}</div>
                                        <div className='small'>
                                            {shipment?.ship_to?.city},{` `}{shipment?.ship_to?.state},{` `}{shipment?.ship_to?.postal_code}
                                        </div>
                                        <div className='small'>{shipment?.ship_to?.phone}</div>
                                    </div>
                                )
                            }
                            <div className='border-bottom border-secondary border-2 my-2'></div>
                            <div className='mt-0'>
                                <div className='font-weight-bold'>Delivery Instructions</div>
                                <div className="small">{shipment.special_instruction}</div>
                            </div>
                            {
                                shipment?.signature_required && (
                                    <div className='d-inline-block mt-2'>
                                        <img src={`/img/signature.png`} className='img-fluid d-inline-block pe-2' alt='logo' height='25' width='25' />
                                        <h6 className='me-3 d-inline-block font-weight-bold'>ADULT SIGNATURE REQUIRED</h6>
                                    </div>
                                )
                            }
                            {
                                shipment?.refrigeration_required && (
                                    <div className='d-inline-block mt-2'>
                                        <img src={`/img/coldchain.png`} className='ms-3 img-fluid d-inline-block pe-2' alt='logo' height='25' width='25' />
                                        <h6 className='d-inline-block font-weight-bold'>COLD CHAIN</h6>
                                    </div>
                                )
                            }
                        </div>
                        <div className='col d-flex flex-column align-items-center mt-4'>
                            {qrCode && (<QRCode value={qrCode} size={150} />)}
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center my-2'>
                        <img src={`/img/logo.png`} className='img-fluid' alt='logo' width='80px' />
                    </div>
                </div>
            }

        </div>
    );
});


export default PrintShipment