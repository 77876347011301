import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { Formik } from 'formik';

import Spinner from '../../components/spinner';
import Header from '../../components/pageHeader';
import { SelectField } from '../../components/selectField';
import { inviteUserHandler, getUsersByID } from '../../services/index';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const InviteUser = (props) => {

    const [loading, showSpinner] = useState(false);
    const [shipperOptions, setShipperOptions] = useState([]);
    const inviteUser = {
        name: '',
        phone: '',
        email: '',
        title: '',
        shipper: [{ value: props?.shipper?.id, label: props?.shipper?.name }],
        role: 'viewer',
    };

    const getShippers = async () => {
        const { shippers } = props;
        let shipperList = [];
        shippers?.length > 0 &&
            shippers.map((item) => {
                return shipperList.push({ value: item?.id, label: item?.name });
            });
        setShipperOptions(shipperList);
    };

    const inviteUserSchema = Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        email: Yup.string().email('Email is invalid.').required('Email is required.'),
        shipper: Yup.array().min(1, 'Select at least one Shipper').of(Yup.object().shape({ label: Yup.string(), value: Yup.string(), }).required('Shipper is required'))
    });

    useEffect(() => {
        getUsers();
        getShippers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shipper]);

    const getUsers = async () => {
        const {
            shipper: { id },
        } = props;
        showSpinner(true);
        const response = await getUsersByID(id);
        if (response) {
            showSpinner(false)
            return response.user;
        } else {
            if (response.toLowerCase() === 'error') {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
        }
    };

    const handleInviteUser = async (user) => {
        showSpinner(true);
        const response = await inviteUserHandler(user);
        if (response === 'update') {
            showSpinner(false);
            toast.success(`${user.name}'s details has been updated`);
            props.history.push('/users')
        } else if (response === 'invited') {
            toast.success(`${user.name} has been invited.`);
            showSpinner(false);
        } else if (response === 'error') {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            showSpinner(false);
        }
    };

    return (
        <Spinner display={loading}>
            <Header name='Users'>
            </Header>
            <div className='card mx-5 runded-5 inviteUser-Card'>
                <div className="card-header">
                    <h2 className="card-header-title">
                        Invite User
                    </h2>
                </div>
                <div className='card-body text-align-center' >
                    <Formik initialValues={inviteUser} validationSchema={inviteUserSchema} onSubmit={handleInviteUser}>
                        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <div className="form-group row mx-sm-2 mx-lg-5 justify-content-center ">
                                    <div className="col-sm-2"></div>
                                    <label className="col-sm-2 text-start col-form-label fs-3">Name  <span className='text-danger fw-bold'>*</span> </label>
                                    <div className="col-sm-6  mb-4 ">
                                        <input type="text" className="form-control mr-5" id="inputPassword" placeholder="e.g. John Doe" name='name' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                                        {errors.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                                    </div>
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2" />
                                    <label className="col-sm-2 text-start col-form-label fs-3">Email ID  <span className='text-danger'>*</span></label>
                                    <div className="col-sm-6 mb-4">
                                        <input type="email" className="form-control" id="inputPassword" placeholder='e.g. john@phoxhealth.com' name='email' value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                                        {errors.email && <div className='text-danger mt-2 ms-1 h5'>{errors.email}</div>}
                                    </div>
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2" />
                                    <label className="col-sm-2 text-start col-form-label fs-3">Shipper  <span className='text-danger'>*</span></label>
                                    <div className="col-sm-6 mb-4">
                                        <SelectField required error={errors.shipper} id='shipper' name='shipper' placeholder='Select' options={shipperOptions} value={values.shipper} isMulti={true} onChange={setFieldValue} isClearable={true} backspaceRemovesValue={true} />
                                    </div>
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2" />
                                    <label className="col-sm-2 text-start col-form-label fs-3">Phone  </label>
                                    <div className="col-sm-6 mb-4">
                                        <input type="phone" className="form-control" name='phone' maxLength='10' placeholder='e.g. +1 983 331 3464' value={values.phone} onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2" />
                                    <label className="col-sm-2 text-start col-form-label fs-3">Title  </label>
                                    <div className="col-sm-6 mb-4">
                                        <input className="form-control" type='text' name='title' placeholder='e.g. Pharmacist' value={values.title} onChange={handleChange} isInvalid={!!errors.title} />
                                    </div>
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2" />
                                    <label className="col-sm-2 text-start col-form-label fs-3">Role  </label>
                                    <div className="col-sm-6 mb-4">
                                        <select className='form-control' name='role' custom value={values.role} onChange={handleChange}>
                                            <option value='viewer'>Viewer</option>
                                            <option value='editor'>Editor</option>
                                            <option value='owner'>Owner</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm-6 d-flex justify-content-end ">
                                        <Link to='/users'>
                                            <button type='button' className='btn btn-white borderd'>
                                                Cancel
                                            </button>
                                        </Link>
                                        <button type='submit' className='btn btn-primary ms-2'>
                                            Invite User
                                        </button>
                                    </div>
                                    <div className="col-sm-2" />
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </Spinner>
    )
}

const mapStateToProps = (state) => {
    return { shipper: state.shipper, shippers: state.shippers };
};
export default connect(mapStateToProps)(InviteUser);