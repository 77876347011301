import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import Avatar from '../../components/avatar';

import * as Yup from 'yup';
import swal from 'sweetalert';
import { Formik } from 'formik';
import { sortBy } from 'lodash';

import Spinner from '../../components/spinner';
import Header from '../../components/pageHeader';
import { updateToken } from '../../helpers/utils';
import { isOwner } from '../../helpers/userHelper';
import { sendInvitationLink, updateUserById, getShipperUserListById, deleteShipperUserById } from '../../services/index';
import { Link } from 'react-router-dom';

const UsersList = (props) => {

  const editUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
  });

  const [loading, showSpinner] = useState(false);
  const [users, setShipperUsers] = useState([]);
  const [currentEditUser, setCurrentEditUser] = useState({});
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [userSearch, setUserSearch] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getUsers();
    getShippers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shipper]);

  const getShippers = async () => {
    const { shippers } = props;
    let shipperList = [];
    shippers?.length > 0 &&
      shippers.map((item) => {
        return shipperList.push({ value: item?.id, label: item?.name });
      });
  };

  const getUsers = async () => {
    const {
      shipper: { id },
    } = props;
    showSpinner(true);
    const response = await getShipperUserListById(id);
    if (response && (response.status === 200 || response.status === 201)) {
      setShipperUsers(response?.data);
      let userData = response?.data;
      userData.map((user) => {
        user.name = user.name?.toLowerCase() || '';
        return user;
      });
      userData = sortBy(response?.data, 'name');
      setShipperUsers(userData);
      showSpinner(false);
    } else {
      if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
        await updateToken();
      }
      if (response?.response?.status >= 500) {
        toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
      }
      showSpinner(false);
    }
  };

  const switchShipper = (shipper) => {
    props.dispatch({ type: 'SHIPPER', payload: shipper });
  };

  useEffect(() => {
    searchUserFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const searchUserFilter = () => {
    let searchUserList = [...users];
    searchUserList = users.filter((value) => value.name.toLowerCase().includes(searchValue.toLowerCase()));
    setUserSearch(searchUserList);
  };

  // const handleInviteUser = async (user) => {
  //   const fixedUserData = { ...user };
  //   user.name = user.name.trim();
  //   user.email = user.email.toLowerCase().trim();
  //   delete user.shipper;
  //   let shipperIds = [];
  //   fixedUserData.shipper.map((item) => {
  //     return shipperIds.push(item.value);
  //   });
  //   // toggleInviteModal();
  //   showSpinner(true);
  //   const response = await getUserByEmail(user.email.toLowerCase().trim());
  //   if (response) {
  //     if (response.status === 200 || response.status === 201) {
  //       const dbUser = response?.data;
  //       const request = { role: user.role };
  //       const result = await inviteUsers(request, dbUser.id, shipperIds);
  //       if (result) {
  //         showSpinner(false);
  //         getUsers();
  //         toast.success(`${user.name}'s details has been updated`);
  //       }
  //     } else {
  //       if (response && response?.response?.status === 404) {
  //         const response = await createUser(user);
  //         if (response && (response.status === 201 || response.status === 200)) {
  //           const dbUser = response?.data;
  //           const request = { role: user.role };
  //           const result = await inviteUsers(request, dbUser.id, shipperIds);
  //           if (result) {
  //             getUsers();
  //             toast.success(`${user.name} has been invited.`);
  //           }
  //           showSpinner(false);
  //         } else if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
  //           await updateToken();
  //         } else if (response?.response?.status >= 500) {
  //           toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
  //         }
  //         showSpinner(false);
  //       }
  //     }
  //   }
  // };


  const handleEditUser = async (user) => {
    if (user.role) {
      setShowEditUserModal(!showEditUserModal);
      showSpinner(async (loading) => {
        loading = !loading;
        const request = {
          role: user?.role,
          name: user?.name?.trim(),
          title: user?.title,
          phone: user?.phone,
        };
        const response = await updateUserById(props.shipper.id, user.id, request);
        if (response && (response.status === 201 || response.status === 200)) {
          showSpinner(!loading);
          getUsers();
          toast.success(`${user.name ? user.name : user.email}'s details has been updated`);
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
          showSpinner(!loading);
        }
      });
    }
  };

  const handleReInvite = async (user) => {
    swal({
      title: `Resend Invitation`,
      text: `Are you sure that you want to resend the invite to ${user.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(true);
        const request = {
          name: user?.name,
        };
        const response = await sendInvitationLink(props?.shipper?.id, user.id, request);
        if (response && (response.status === 201 || response.status === 200)) {
          getUsers();
          toast.success(`Invite has been sent to ${user.name}.`);
          showSpinner(false);
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            await updateToken();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      } else {
        showSpinner(false);
      }
    });
  };
  const doRemove = async (user) => {
    const name = user.name ? user.name : user.email;
    swal({
      title: `Remove ${name}`,
      text: `Are you sure that you want to remove ${name} from ${props.shipper.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then(async (status) => {
      if (status) {
        showSpinner(async (loading) => {
          loading = !loading;
          const response = await deleteShipperUserById(props.shipper.id, user.id);
          if (response && (response.status === 201 || response.status === 200)) {
            showSpinner(!loading);
            getUsers();
            toast.success(`${name} has been removed.`);
          } else {
            if (response.response.status === 401 || response.response.data === 'Unauthorized') {
              await updateToken();
            }
            if (response.response.status >= 500) {
              toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
            showSpinner(false);
          }
        });
      } else {
        showSpinner(false);
      }
    });
  };


  const toggleEditUserModal = async (event, user = '') => {
    if (user) setCurrentEditUser({ ...user, role: user.roles.shipper[props.shipper.id] });
    setShowEditUserModal(!showEditUserModal);
  };
  const { shipper } = props;
  return (
    <Spinner display={loading}>
      <Header name='Users'>
        {isOwner(shipper) && (
          <Link to='/invite-user'>
            <button type='button' className='btn btn-primary' >
              <i className='fe fe-user-plus'></i> Invite User
            </button>
          </Link>
        )}
      </Header>
      <div className="card mx-5" data-list="{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-title&quot;, &quot;item-email&quot;, &quot;item-phone&quot;, &quot;item-score&quot;, &quot;item-company&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}" id="contactsList">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="input-group input-group-flush input-group-merge input-group-reverse">
                <input className="form-control list-search" type="search" placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                <span className="input-group-text">
                  <i className="fe fe-search"></i>
                </span>
              </div>
            </div>
            <div className='col-auto px-3'>
              <div className='dropdown'>
                <button className='btn btn-sm btn-light dropdown-toggle w-100 text-wrap' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                  {props.shipper.name}
                </button>
                <div className='dropdown-menu dropdown-menu-right ' aria-labelledby='dropdownMenuButton'>
                  <button
                    className='dropdown-item'
                    onClick={() =>
                      switchShipper({
                        alias: "All Locations",
                        id: "",
                        name: "All Locations",
                        role: "All Locations",
                      })}>
                    All Locations
                  </button>

                  {props?.shippers?.map((shipper) => {
                    return (
                      <button
                        key={`menu_${shipper.id}`}
                        className='dropdown-item'
                        onClick={() => {
                          switchShipper(shipper);
                        }}>
                        {shipper?.name}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive ">
          <table className="table table-sm table-hover table-nowrap card-table ">
            <thead>
              <tr>
                <th>
                  <div className='col-3 col-sm-3 col-lg-3 m-2 ms-5 mt-3 align-self-center fw-bold text-center'>Name</div>
                </th>
                <th>
                  <div className='col-2 col-sm-2 col-lg-3 m-2 mt-3 text-center align-self-center fw-bold'>Title</div>
                </th>
                <th>
                  <div className='col-3 col-sm-3 col-lg-3 m-2 mt-3 ps-lg-6 text-center align-self-center fw-bold'>Role</div>
                </th>
                <th>
                  <div className='col-2 col-sm-3 col-lg-2 mt-2 text-end align-self-center fw-bold'>Action</div>
                </th>
              </tr>
            </thead>
            {users && (
              <tbody className="list fs-base">
                {userSearch?.length > 0
                  ? userSearch?.map((user, index) => {
                    return <>
                      <tr key={index}>
                        <td>
                          <div className='col-12 col-md-12 col-sm-3 col-lg-4 m-2 d-flex ms-2' onClick={() => props.history.push(`/userProfile/${user.id}`)}>
                            <div className='mx-4'>
                              {user?.photo_url ? <Avatar image={user.photo_url} /> : <Avatar />}
                            </div>
                            <div>
                              <div className='text-capitalize '>{user.name}</div>
                              <div className='text-muted fs-3'>{user.email}</div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="item-title">  {user.title}</span>
                        </td>
                        <td>
                          <div className='col-4 col-md-4 col-sm-3 col-lg-3 ms-5 ps-5 align-self-center'>
                            {user.roles.shipper[props.shipper.id] === 'owner' && (
                              <span className='badge bg-danger-soft fw-bold' >
                                Owner
                              </span>
                            )}
                            {user.roles.shipper[props.shipper.id] === 'editor' && (
                              <span className='badge bg-primary-soft fw-bold' >
                                Editor
                              </span>
                            )}
                            {user.roles.shipper[props.shipper.id] === 'viewer' && (
                              <span className='badge bg-secondary-soft fw-bold' >
                                Viewer
                              </span>
                            )}
                          </div>
                        </td>
                        {isOwner(props.shipper) ? (
                          <td>
                            <div className='col-3 col-md-3 col-sm-2 col-lg-1 text-start align-self-center'>
                              <div className='dropdown'>
                                <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                  <i className='fe fe-more-vertical text-dark'></i>
                                </a>
                                <div className='dropdown-menu dropdown-menu-right'>
                                  <button type='button' className='dropdown-item' onClick={(e) => { toggleEditUserModal(e, user); }}>
                                    Edit
                                  </button>
                                  <button type='button' className='dropdown-item' onClick={() => { handleReInvite(user); }} >
                                    Resend Invite
                                  </button>
                                  <button type='button' className='dropdown-item text-danger' onClick={() => { doRemove(user); }}>
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        ) : (
                          <div></div>
                        )}
                      </tr>
                    </>
                  }) :
                  users?.map((user, index) => {
                    return <>
                      <tr key={index}>
                        <td>
                          <div className='col-12 col-md-12 col-sm-3 col-lg-4 m-2 d-flex ms-2' onClick={() => props.history.push(`/userProfile/${user.id}`)}>
                            <div className='mx-4'>
                              {user?.photo_url ? <Avatar image={user.photo_url} /> : <Avatar />}
                            </div>
                            <div>
                              <div className='text-capitalize '>{user.name}</div>
                              <div className='text-muted fs-3'>{user.email}</div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="item-title">  {user.title}</span>
                        </td>
                        <td>
                          <div className='col-4 col-md-4 col-sm-3 col-lg-3 ms-5 ps-5 align-self-center'>
                            {user.roles.shipper[props.shipper.id] === 'owner' && (
                              <span className='badge bg-danger-soft fw-bold' >
                                Owner
                              </span>
                            )}
                            {user.roles.shipper[props.shipper.id] === 'editor' && (
                              <span className='badge bg-primary-soft fw-bold' >
                                Editor
                              </span>
                            )}
                            {user.roles.shipper[props.shipper.id] === 'viewer' && (
                              <span className='badge bg-secondary-soft   fw-bold' >
                                Viewer
                              </span>
                            )}
                          </div>
                        </td>

                        {isOwner(props.shipper) ? (
                          <td>
                            <div className='col-3 col-md-3 col-sm-2 col-lg-1 text-start align-self-center'>
                              <div className='dropdown'>
                                <a href='/#' className='dropdown-ellipses dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                                  <i className='fe fe-more-vertical text-dark' ></i>
                                </a>
                                <div className='dropdown-menu dropdown-menu-right'>
                                  <button
                                    type='button'
                                    className='dropdown-item'
                                    onClick={(e) => {
                                      toggleEditUserModal(e, user);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    type='button'
                                    className='dropdown-item'
                                    onClick={() => {
                                      handleReInvite(user);
                                    }}
                                  >
                                    Resend Invite
                                  </button>
                                  <button
                                    type='button'
                                    className='dropdown-item text-danger'
                                    onClick={() => {
                                      doRemove(user);
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        ) : (
                          <div></div>
                        )}
                      </tr>
                    </>
                  })}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* edit user */}
      <Modal show={showEditUserModal} onHide={toggleEditUserModal}>
        <Modal.Body>
          <Modal.Title className='h1'> Edit User</Modal.Title>
          <hr />
          <Formik initialValues={currentEditUser} validationSchema={editUserSchema} onSubmit={handleEditUser}>
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className='form-group'>
                  <label className='mb-2'>
                    Name<span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} isInvalid={!!errors.name} />
                  {errors.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                </div>
                <div className='form-group'>
                  <label className='mb-2'>Phone</label>
                  <input className='form-control' type='phone' name='phone' maxLength='10' placeholder='e.g. +1 983 331 3464' value={values.phone} onChange={handleChange} />
                </div>
                <div className='form-group'>
                  <label className='mb-2'>Title</label>
                  <input className='form-control' type='text' name='title' placeholder='e.g. Pharmacist' value={values.title} onChange={handleChange} isInvalid={!!errors.title} />
                </div>

                <div className='form-group'>
                  <label className='mb-2'>Role</label>
                  <select className='form-control' as='select' name='role' custom value={values.role} onChange={handleChange}>
                    <option value='viewer'>Viewer</option>
                    <option value='editor'>Editor</option>
                    <option value='owner'>Owner</option>
                  </select>
                </div>
                <div className='d-flex justify-content-end'>
                  <button type='button' className='btn btn-white' onClick={toggleEditUserModal}>
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-primary ms-2'>
                    Update
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </Spinner>
  );
};

const mapStateToProps = (state) => {
  return { shipper: state.shipper, shippers: state.shippers };
};
export default connect(mapStateToProps)(UsersList);