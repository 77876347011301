import React, { useEffect } from 'react'
import Spinner from '../../components/spinner'
import Header from '../../components/pageHeader';
import { useState } from 'react';
import { toast } from 'react-toastify';
import convert from 'xml-js';
import { GoogleApiWrapper } from 'google-maps-react';
import { connect } from 'react-redux';
import AsyncCreatableSelect from "react-select/async-creatable";
import { Link } from 'react-router-dom';
import fedexboxData from '../../Data/fedexBoxData';
import { Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { updateToken } from '../../helpers/utils';
import * as Yup from 'yup';
import BarcodeReader from 'react-barcode-reader';
import { getShippersById, addEditShipper, getDefaultProductType, getProductTypeById, getOrderNumberSettings, getOrderNumberSettingsById, getShipmentFormData, validateAddress, getUspsLabel } from '../../services/index';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import InputMask from 'react-input-mask';
import axios from 'axios';

function PhoneInput(props) {
  return (
    <InputMask
      mask='(999) 999-9999'
      value={props.value}
      onChange={props.onChange}
      placeholder="( _ _ _ ) _ _ _ _ - _ _ _" style={{ border: "none", outline: "none" }}
    >
    </InputMask>
  );
}

const NewUSPSShipment = (props) => {
  const [loading, showSpinner] = useState(false);
  const [isOrderNumberRequired, setOrderNumberRequired] = useState(true);
  const [isLabOrder, setLabOrder] = useState(false);
  const [orderList, setOrderList] = useState([{ order_number: '', product_type: '' }])
  const [state, setState] = useState([])
  const [shipperDetails, setShipperDetails] = useState({});
  const [productTypes, setProductTypes] = useState([]);
  const [defaultProductType, setDefaultProductType] = useState('');
  const [VerifiedAddress, setVerifiedAddress] = useState(false)
  const [boxsizeOption, setBoxsizeOption] = useState(fedexboxData);
  const [boxSize, setBoxSize] = useState('')
  const [qrCodeData, setQRCodeData] = useState('');
  const [modalConfirmOrder, showConfirmOrderModal] = useState(false);
  const [isDataValid, setDataValid] = useState({
    postal_code: '',
    contact_name: '',
    secondary_contact_name: '',
  });

  const [boxSizeModal, setBoxSizeModal] = useState(false)
  const shipperList = props.shippers;
  const shipperName = props.shipper.name;
  const [formData, setFormData] = useState({
    order_number: '',
    contact_name: '',
    secondary_contact_name: '',
    company_name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    country_code: 'US',
    location: {},
    alias: '',
    group_name: '',
    parcels: '1',
    delivery_instructions: '',
    adult_signature: false,
    order_code: '',
    selectedProductType: '',
    carrier_alias: shipperName === 'MetroHealth Mail Order Pharmacy' || shipperName === 'MetroHealth Specialty Pharmacy' ? 'USPS' : '',
    boxSize: '',
    weight: ''
  });
  const [addressData, setAddressData] = useState({
    contact_name: '',
    secondary_contact_name: '',
    company_name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    country_code: 'US'
  });
  const customboxSizeSchema = Yup.object().shape({
    length: Yup.string().required('length is required').matches(/(\d*[0-9]+\d*)+/, 'Please enter a valid number').min(1).max(4),
    width: Yup.string().required('width is required').matches(/(\d*[0-9]+\d*)+/, 'Please enter a valid number').min(1).max(4),
    height: Yup.string().required('height is required').matches(/(\d*[0-9]+\d*)+/, 'Please enter a valid number').min(1).max(4),
  })
  useEffect(() => {
    getProductsType();
    getShipperDetails()
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [props.shipper.id]);

  useEffect(() => {
    if (qrCodeData.length > 0) {
      const data = qrCodeData.split('^');
      setFormData({ ...formData, order_number: data[0] });
      calculateMiles(data);
    }
  }, [qrCodeData]);

  useEffect(() => {
    enableValidateFunc()
  }, [addressData.address1, addressData.city, addressData.state, addressData.postal_code])


  const calculateMiles = async (data) => {
    const address = `${data[4]},${data[5]}, ${data[6]}, ${data[7]}`;
    const selectedAddress = await geocodeByAddress(address);
    const LatLong = await getLatLng(selectedAddress[0]);
    let location = {};
    if (LatLong) {
      location._latitude = LatLong.lat;
      location._longitude = LatLong.lng;
      // const shipToLocation = {
      //   latitude: LatLong.lat,
      //   longitude: LatLong.lng,
      // };
    }
    setAddressData({
      ...addressData,
      contact_name: data[1],
      email: data[3],
      phone: data[2],
      address1: data[4],
      address2: '',
      location: location,
      city: data[5],
      state: data[6],
      postal_code: data[7],
    })

  };


  const enableValidateFunc = () => {

    //disabling the getShipmentLabel and Create Shipment on Edit of Address
    if (formData?.address1 !== addressData?.address1 || addressData?.address1 === '') {
      setVerifiedAddress(false)
    } else if (formData?.address2 !== addressData?.address2 || addressData?.address2 === '') {
      setVerifiedAddress(false)
    } else if (formData?.state !== addressData?.state || addressData?.state === '') {
      setVerifiedAddress(false)
    } else if (formData?.city !== addressData.city || addressData?.city === '') {
      setVerifiedAddress(false)
    } else if (formData?.postal_code !== addressData.postal_code || addressData?.postal_code === '') {
      setVerifiedAddress(false)
    } else {
      setVerifiedAddress(true)
    }

  }



  const onAddressChange = ({ target: { name, value, checked } }) => {
    setAddressData({
      ...addressData,
      [name]: value,
    });
    //disabling the getShipmentLabel and Create Shipment on Edit of Address

  }
  const onInputChange = ({ target: { name, value, checked } }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'adult_signature' || name === 'save_address') {
      setFormData({
        ...formData,
        [name]: checked,
      });
    }
    if (name === 'postal_code' && value.length > 0) {
      if (!/^[0-9]*$/.test(formData?.postal_code)) {
        setDataValid({ ...isDataValid, postal_code: 'Please Enter the valid postal code' });
      } else {
        setDataValid({ ...isDataValid, postal_code: '' });
      }
    }
    if (name === 'contact_name' && value.length > 0) {
      if (!/(\d*[a-zA-Z]+\d*)+/.test(formData?.contact_name)) {
        setDataValid({ ...isDataValid, contact_name: 'Name cannot contain only numbers' });
      } else {
        setDataValid({ ...isDataValid, contact_name: '' });
      }
    }
    if (name === 'secondary_contact_name' && value.length > 0) {
      if (!/(\d*[a-zA-Z]+\d*)+/.test(formData?.secondary_contact_name)) {
        setDataValid({ ...isDataValid, secondary_contact_name: 'C/O cannot contain only numbers' });
      } else {
        setDataValid({ ...isDataValid, secondary_contact_name: '' });
      }
    }
  };

  const handleAddRecord = (e) => {
    e.preventDefault();
    const values = [...orderList];
    values.push({
      product_type: '',
      order_number: '',
    });
    setOrderList(values);
  };

  const handleChangeRecord = (index, event, name) => {
    const values = [...orderList];
    if (name === 'orderNumber') {
      values[index].order_number = event.target.value;
    } else if (name === 'productType') {
      values[index].product_type = event.target.value;
    }
    setOrderList(values);
  };

  const handleRemoveRecord = (e, index) => {
    e.preventDefault();
    if (index !== 0) {
      const values = [...orderList];
      values.splice(index, 1);
      setOrderList(values);
    } else {
      toast.error(`Atleast 1 Parcel is required`);
    }
  };
  const Validity = () => {
    let validityMessage = '';
    const orderValidity = formData?.order_number?.length > 0;
    if (isOrderNumberRequired && !orderValidity) {
      validityMessage = 'Please enter the order number.';
    }
    if (!formData.weight) {
      validityMessage = 'Please enter the Box weight.';
    }
    if (!formData.boxSize) {
      validityMessage = 'Please enter the Box Size.';
    }
    return validityMessage;
  };

  const ValidAddress = () => {
    let validityMessage = '';
    if (!addressData.contact_name) {
      validityMessage = 'Please enter the Recipient Name.';
    } else if (!addressData.address1) {
      validityMessage = 'Please enter the Address 1.';
    } else if (!addressData.city) {
      validityMessage = 'Please enter the City.';
    } else if (!addressData.state) {
      validityMessage = 'Please enter the State.';
    } else if (!addressData.postal_code) {
      validityMessage = 'Please enter the Postal Code.';
    }
    return validityMessage;
  };

  const customboxSize = {
    length: '',
    width: '',
    height: '',
  }

  const handleSelectBox = (value) => {
    if (value === "other") {
      setBoxSizeModal(true)
    } else {
      setBoxSize(value)
      setFormData({
        ...formData,
        boxSize: value
      })
    }
  }
  const getCustomBoxSize = (e) => {
    let value = {
      id: (boxsizeOption.length + 1),
      type: `custom value (${e.length} X ${e.width} X ${e.height})`
    }

    setBoxsizeOption([
      ...boxsizeOption,
      value]
    )
    setFormData({
      ...formData,
      boxSize: value.type
    })
    setBoxSize(value.type)
    setBoxSizeModal(false)
  }
  const closeBoxModal = () => {
    if (!boxSizeModal) {
      setBoxSize(boxsizeOption[0]?.type)
    }
    setBoxSizeModal(false)
  }

  const getOrderNumberSetting = async (shipperId) => {
    try {
      const response = await getOrderNumberSettingsById(shipperId);

      if ((response.status === 200 || response.status === 201) && response?.data.length) {
        setOrderNumberRequired(response?.data[0]?.value === `true` ? true : false);
      } else {
        const response = await getOrderNumberSettings();

        if ((response.status === 200 || response.status === 201) && response?.data) {
          setOrderNumberRequired(response?.data[0]?.value === `true` ? true : false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getShipperDetails = async () => {
    try {
      const response = await getShippersById(props.shipper.id);

      if (response && (response.status === 200 || response.status === 201)) {
        setShipperDetails(response?.data);

        await getOrderNumberSetting(props.shipper.id);

        if (response?.data?.group_name?.toLowerCase() === 'lab') {
          setLabOrder(true);
          setFormData({
            ...formData,
            adult_signature: true,
          });
        } else {
          setLabOrder(false);
          setFormData({
            ...formData,
            adult_signature: false,
          });
        }

        if (response?.data?.group_name?.toLowerCase() === 'trinity') {
          setFormData({ ...formData, adult_signature: true });
        }
      } else {
        if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
          await updateToken();
        }
        if (response?.response?.status >= 500) {
          toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProductsType = async () => {
    showSpinner(true);
    try {
      const response = await getProductTypeById(props.shipper.id);
      if (response && (response.status === 200 || response.status === 201)) {
        if (response?.data && response.data?.length > 0) {
          setProductTypes(response?.data);
          setDefaultProductType(response?.data && response?.data[0].name);
          showSpinner(false);
        } else {
          const defaultProductTypes = await getDefaultProductType();
          setProductTypes(defaultProductTypes?.data);
          setDefaultProductType(defaultProductTypes?.data && defaultProductTypes?.data[0].name);
          showSpinner(false);
        }
      } else {
        showSpinner(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleScan = (data) => {
    setQRCodeData(data.replace(/[\\/]/g, ''));
  };

  const handleError = (err) => {
    console.error('scan error', err);
    window.location.reload();
  };
  const switchShipper = ({ target: { value } }) => {
    const shipper = shipperList.find((item) => item.name?.toLowerCase() === value?.toLowerCase());
    if (shipper) {
      props.dispatch({ type: 'SHIPPER', payload: shipper });
      if (shipper.role === 'viewer') {
        toast.error(`You don't have access to create a shipment for ${shipper.name}. Please contact admin`);
        props.history.push('/shipments');
      }
    }
  };

  const handleInput = ({ target: { value } }) => setAddressData({
    ...addressData,
    phone: value
  });

  const items = async (keyword) => {
    let items = []
    let response = await getShipmentFormData(props?.shipper?.id, keyword);
    if (response) {
      if (response.status === 200 || response.status === 201) {
        if (response.data.length >= 1) {
          setState(response.data)
          response.data.forEach((elem) => {
            let address = ''
            if (elem.address1) address = address.concat(elem.address1 + ', ')
            if (elem.address2) address = address.concat(elem.address2 + ', ')
            if (elem.city) address = address.concat(elem.city + ', ')
            if (elem.state) address = address.concat(elem.state + ' ')
            if (elem.postal_code) address = address.concat(elem.postal_code + ' ')
            items.push({
              value:
              {
                name: `${elem?.contact_name}`,
                phone: `${elem?.phone}`,
                email: `${elem?.email}`,
                postal_code: `${elem?.postal_code}`,
              }, label: `${elem.contact_name} (${address})`
            })
          })
        }
        return items
      }
    }
  }

  const onChangeFunction = (e) => {
    const array = state.filter((elem) => {
      return (
        elem?.contact_name === e?.value?.name
        && elem?.phone === e?.value?.phone
        && elem?.email === e?.value?.email
        && elem?.postal_code === e?.value?.postal_code
      )
    })
    if (array.length !== 0) {
      array.forEach(async (elem) => {
        setAddressData({
          contact_name: `${elem?.contact_name}`,
          secondary_contact_name: `${elem?.secondary_contact_name}`,
          company_name: `${elem?.company_name}`,
          email: `${elem?.email}`,
          phone: `${elem?.phone}`,
          address1: `${elem?.address1}`,
          address2: `${elem?.address2}`,
          city: `${elem?.city}`,
          state: `${elem?.state}`,
          postal_code: `${elem?.postal_code}`,
          location: elem?.location,
          save_address: false,
        })
        // if (elem.location) {
        //   const shipToLocation = {
        //     latitude: elem.location._latitude,
        //     longitude: elem.location._longitude,
        //   };
        // }
      })
    }
    else {
      setAddressData({
        contact_name: `${e?.value ? e?.value : ""}`,
        secondary_contact_name: '',
        company_name: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postal_code: '',
        location: {},
        save_address: false,
      }
      )
    }
  }

  const handleAddress1Change = (address1) => {
    setAddressData({
      ...addressData,
      address1: address1,
    });

    if (addressData.address1 !== formData.address1 || addressData.address1 === '') {
      setVerifiedAddress(false)
    }
  };

  const handleAddressSelect = async (value) => {
    const selectedAddress = await geocodeByAddress(value);
    const LatLong = await getLatLng(selectedAddress[0]);
    let street_number = '';
    let sub_locality1 = '';
    let sub_locality2 = '';
    let sub_locality3 = '';
    let sub_locality4 = '';
    let route = '';
    let city = '';
    let state = '';
    let postal_code = '';
    let location = {};
    if (LatLong) {
      location._latitude = LatLong.lat;
      location._longitude = LatLong.lng;
      // const shipToLocation = {
      //   latitude: LatLong.lat,
      //   longitude: LatLong.lng,
      // };
    }
    !!selectedAddress[0].address_components?.length &&
      selectedAddress[0].address_components.forEach((address) => {
        if (address.types.includes('street_number')) street_number = address.short_name;
        if (address.types.includes('sublocality_level_3')) sub_locality1 = address.short_name;
        if (address.types.includes('sublocality_level_2')) sub_locality2 = address.short_name;
        if (address.types.includes('sublocality_level_1')) sub_locality3 = address.short_name;
        if (address.types.includes('country')) sub_locality4 = address.long_name;
        if (address.types.includes('route')) route = address.short_name;
        if (address.types.includes('locality')) city = address.short_name;
        if (address.types.includes('administrative_area_level_1')) state = address.short_name;
        if (address.types.includes('postal_code')) postal_code = address.long_name;
      });

    setAddressData({
      ...addressData,
      address1: street_number ? `${street_number} ${route}` : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route} ${sub_locality4}`,
      postal_code,
      city,
      state,
      location,
    });
  };

  const validateAddressfun = async () => {

    const validityMessage = await ValidAddress();
    if (validityMessage.length <= 0) {
      setFormData({
        ...formData,
        contact_name: formData?.contact_name,
        secondary_contact_name: formData?.secondary_contact_name,
        company_name: formData?.company_name,
        phone: formData?.phone,
        email: formData?.email,
        address1: formData?.address1,
        address2: formData?.address2,
        city: formData?.city,
        state: formData?.state,
        postal_code: formData?.postal_code,
        country_code: 'US'
      })

      let req = {
        contact_name: addressData?.contact_name,
        secondary_contact_name: addressData?.secondary_contact_name,
        company_name: addressData?.company_name,
        phone: addressData?.phone,
        email: addressData?.email,
        address1: addressData?.address1,
        address2: addressData?.address2,
        city: addressData?.city,
        state: addressData?.state,
        postal_code: addressData?.postal_code,
        country_code: 'US',
      };
      showSpinner(true)
      let jsonStr = ''
      const res = await validateAddress(req)
      if (res) {
        let xmlData = convert.xml2json(res.data, { compact: true, spaces: 4 });
        let jsonObj = (JSON.parse(xmlData)['soap:Envelope']['soap:Body'].ValidateAddressResponse?.ValidateAddressResponse?.AddressCleansingResult?._text);
        jsonStr = jsonObj
        if (jsonStr === "Full Address Verified.") {
          setVerifiedAddress(true)
          showSpinner(false)
          toast.success('Address Verified')
        } else {
          setVerifiedAddress(false)
          showSpinner(false)
          toast.error(jsonStr)
        }
      } else {
        showSpinner(false)
        toast.error('something goes wrong')
      }
    } else {
      toast.error(validityMessage)
    }

  }

  const getShipmentLabel = async () => {
    const validityMessage = await Validity();
    if (validityMessage.length <= 0) {
      let parcelData = [];
      orderList.forEach((item, index) => {
        parcelData.push({
          description: `Item ${index}`,
          productType: item?.product_type?.length > 0 ? item.product_type : defaultProductType,
          reference_number: item?.order_number,
        });
      });
      const updatedParcelData = [{ items: parcelData }];
      let delivery_instructions = formData.delivery_instructions;
      if (formData.adult_signature) {
        delivery_instructions = formData.delivery_instructions?.length > 0 ? `${delivery_instructions}, Adult signature is required.` : 'Adult signature is required.';
      }
      try {
        let request = {
          status: 'Ready for Pickup',
          special_instruction: formData.delivery_instructions,
          signature_required: formData.adult_signature,
          order_code: formData.order_code,
          order_number: formData.order_number?.trim()?.replace('/', '').replace('\\', ''),
          delivery_instructions,
          delivery_timeslot: isLabOrder ? '' : formData.delivery_timeslot,
          service_type: '',
          parcels: updatedParcelData,
          service_delivery_date: new Date(formData?.service_delivery_date),
          carrier_alias: formData.carrier_alias,
          weight: formData.weight,
          boxsize: boxSize,
          test: 'YES',
          ship_to: {
            name: addressData?.name,
            contact_name: addressData?.contact_name,
            company_name: addressData?.company_name,
            secondary_contact_name: addressData?.secondary_contact_name,
            phone: addressData?.phone,
            email: addressData?.email?.toLowerCase()?.trim(),
            address1: addressData?.address1?.trim(),
            address2: addressData?.address2?.trim(),
            location: addressData?.location,
            city: addressData?.city?.trim(),
            state: addressData?.state?.trim(),
            postal_code: addressData?.postal_code,
            country_code: addressData?.country_code,
          },

          ship_from: {
            shipper_name: props.shipper.name,
            shipper_id: props.shipper.id,
            user_name: props.user.name,
            email: props.user.email.toLowerCase()?.trim(),
            user_id: props.user.id,
            alias: shipperDetails.alias,
            phone: shipperDetails?.phone,
            group_name: shipperDetails.group_name,
            address1: shipperDetails?.address?.address1?.trim(),
            address2: shipperDetails?.address?.address2?.trim(),
            location: shipperDetails?.address?.location,
            city: shipperDetails?.address?.city?.trim(),
            state: shipperDetails?.address?.state?.trim(),
            postal_code: shipperDetails?.address?.postal_code,
            timezone: shipperDetails?.address?.timezone || {},
          },

        };

        showSpinner(true)
        let res = await getUspsLabel(request);
        if (res) {
          let xmlData = convert.xml2json(res.data, { compact: true, spaces: 4 });
          let lableImg = (JSON.parse(xmlData)['soap:Envelope']['soap:Body'].GetPostageLabelResponse.LabelRequestResponse.Base64LabelImage._text);
          // setShipmentLable(lableImg)

          var a = document.createElement("a");
          a.href = "data:image/png;base64," + lableImg;
          a.download = `TEST-USPS-Label-${formData.order_number}.png`;
          a.click();
          showSpinner(false)
        } else {
          toast.error('Please check the data you entered ')
        }
      } catch (error) {
        console.log(error);
        showSpinner(false)
      }
    } else {
      toast.error(validityMessage);
    }

  }

  const confirmShipment = async () => {
    const validityMessage = await Validity();
    if (validityMessage?.length <= 0) {
      showConfirmOrderModal(true);
    } else {
      toast.error(validityMessage);
      showConfirmOrderModal(false);
    }
  };

  const onAddNewShipment = async () => {
    const validityMessage = await Validity();
    if (validityMessage?.length <= 0) {
      let parcelData = [];
      orderList.forEach((item, index) => {
        parcelData.push({
          description: `Item ${index}`,
          productType: item?.product_type?.length > 0 ? item.product_type : defaultProductType,
          reference_number: item?.order_number,
        });
      });
      const updatedParcelData = [{ items: parcelData }];
      let delivery_instructions = formData.delivery_instructions;
      if (formData.adult_signature) {
        delivery_instructions = formData.delivery_instructions?.length > 0 ? `${delivery_instructions}, Adult signature is required.` : 'Adult signature is required.';
      }
      try {
        let request = {
          status: 'Ready for Pickup',
          special_instruction: formData.delivery_instructions,
          signature_required: formData.adult_signature,
          order_code: formData.order_code,
          order_number: formData.order_number?.trim()?.replace('/', '').replace('\\', ''),
          delivery_instructions,
          delivery_timeslot: isLabOrder ? '' : formData.delivery_timeslot,
          service_type: '',
          parcels: updatedParcelData,
          service_delivery_date: new Date(formData?.service_delivery_date),
          carrier_alias: formData.carrier_alias,
          weight: formData.weight,
          boxsize: boxSize,
          test: 'NO',
          ship_to: {
            name: addressData?.name,
            contact_name: addressData?.contact_name,
            company_name: addressData?.company_name,
            secondary_contact_name: addressData?.secondary_contact_name,
            phone: addressData?.phone,
            email: addressData?.email?.toLowerCase()?.trim(),
            address1: addressData?.address1?.trim(),
            address2: addressData?.address2?.trim(),
            location: addressData?.location,
            city: addressData?.city?.trim(),
            state: addressData?.state?.trim(),
            postal_code: addressData?.postal_code,
            country_code: addressData?.country_code,
          },

          ship_from: {
            shipper_name: props.shipper.name,
            shipper_id: props.shipper.id,
            user_name: props.user.name,
            email: props.user.email.toLowerCase()?.trim(),
            user_id: props.user.id,
            alias: shipperDetails.alias,
            phone: shipperDetails?.phone,
            group_name: shipperDetails.group_name,
            address1: shipperDetails?.address?.address1?.trim(),
            address2: shipperDetails?.address?.address2?.trim(),
            location: shipperDetails?.address?.location,
            city: shipperDetails?.address?.city?.trim(),
            state: shipperDetails?.address?.state?.trim(),
            postal_code: shipperDetails?.address?.postal_code,
            timezone: shipperDetails?.address?.timezone || {},
          },

        };
        showSpinner(true);
        const response = await addEditShipper('shipment', request);
        if (response) {
          if (response.status === 200 || response.status === 201) {
            // Generate Shipping Label
            let data = response.data
            data.Test = "NO"
            try {
              const res = await axios.post('endicia/postage_label', data);
              let xmlData = convert.xml2json(res.data, { compact: true, spaces: 4 });
              let lableImg = (JSON.parse(xmlData)['soap:Envelope']['soap:Body'].GetPostageLabelResponse.LabelRequestResponse.Base64LabelImage._text);
              // setShipmentLable(lableImg)
              var a = document.createElement("a");
              a.href = "data:image/png;base64," + lableImg;
              a.download = `USPS-Label-${request.order_number}.png`;
              a.click();
              // var doc = new jsPDF();
              // doc.addImage(`data:application/pdf;base64,${lableImg}`, 15, 40, 180, 180);
              // doc.save(`USPS-Label-${request.order_number}.pdf`);

              if (request.ship_from.shipper_name.includes('MetroHealth')) {
                props.history.push(`/shipment/${response.data.id}/overview`, {
                  print: true
                })
              } else { props.history.push('/shipments') }
              showConfirmOrderModal(false);
              const message = response?.data?.number ? `# ${response?.data?.number} for ${formData.contact_name} has been added to shipment` : `New Order has been added to shipment`;
              toast.success(message);
            } catch (error) {
              toast.error('Oops! Looks like there was an error while trying to create a USPS label. Please contact us at care@phoxhealth.com');
            }
          } else {
            if (response?.response?.status === 409) {
              toast.error(response.response.data.error);
            }
            if (response?.response?.status === 400 && response?.response?.data.error_code === 'max_distance') {
              toast.error(response.response.data.error);
            }
            if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized' || response?.response?.status >= 500) {
              await updateToken();
            }
            showConfirmOrderModal(false);
          }
          showSpinner(false);
        }
        showSpinner(false);
      } catch (error) {
        showSpinner(false);
        showConfirmOrderModal(false)
      }
    } else {
      toast.error(validityMessage);
    }
  };
  // eslint-disable-next-line
  let regex = /[a-zA-Z!@#\$%\^\&*\)\(+=_-]/;
  return (
    <Spinner display={loading}>
      <Header name='New	 Shipment' />
      <div className="container-fluid">
        <form >
          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Package Details
              </h4>
            </div>
            <div className="card-body">
              <div className='row'>
                <div className='form-group'>
                  <label className='mb-2'>
                    Pickup location <span className='text-danger'>*</span>
                  </label>
                  <select className='form-control' defaultValue={shipperName === "All Locations" ? null : shipperName} onChange={(event) => switchShipper(event)}>
                    <option defaultValue='' >
                      Metrohealth Mail Order Pharmacy
                    </option>
                    <option >
                      Metrohealth Specialty Pharmacy
                    </option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="form-Group">
                  <label >
                    Order No./Reference No.  <span className='text-danger'>*</span>
                  </label>
                  <input className='form-control' type='text' name='order_number' placeholder='e.g. AB12-TS322' value={formData?.order_number} onChange={onInputChange} isInvalid={isOrderNumberRequired} />
                  {isOrderNumberRequired && formData?.order_number === '' && <small className='text-danger'>Order Number is required</small>}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className='header-title'>
                Box Details
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2 text-muted small'>Weight (LBS) <span className='text-danger'>*</span> </label>
                    <input className='form-control' type='text' name='weight' placeholder='e.g. 5 lbs' value={formData?.weight} onChange={onInputChange} />
                    {formData?.weight === '' && <small className='text-danger'>Required</small>}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group mb-0'>
                    <label className='mb-2 text-muted small'>Box Type  <span className='text-danger'>*</span> </label>
                    <select className='form-control' value={boxSize} id='index' placeholder='select box size' onChange={(e) => { handleSelectBox(e.target.value) }} >
                      {boxsizeOption?.length > 0 &&
                        boxsizeOption?.map(({ id, type }, index) => {
                          return (
                            <option key={index} defaultValue={type} selected={`${boxsizeOption[boxsizeOption?.length - 1]?.id > 6 ? boxsizeOption[boxsizeOption?.length - 1]?.type : ''}`}   >
                              {type}
                            </option>
                          );
                        })}
                      <option value='other'>
                        Other
                      </option>
                    </select>
                    {!boxSize && <small className='text-danger'> Required</small>}
                    <Modal show={boxSizeModal} onHide={() => setBoxSizeModal(false)} centered>
                      <Modal.Body>
                        <Formik enableReinitialize={true} initialValues={customboxSize} validationSchema={customboxSizeSchema} onSubmit={getCustomBoxSize}>
                          {({ handleChange, handleSubmit, values, errors }) => (
                            <form noValidate onSubmit={handleSubmit}>
                              <div className="boxSize-header text-center d-flex justify-content-center">
                                <h1>Enter Values</h1>
                              </div>
                              <div className='form-group text-center d-flex  flex-row justify-content-center text-center'>
                                <div>
                                  <input className='form-control mx-2' style={{ width: "80px" }} type='text' maxLength='5' name='length' placeholder='length' value={values.length.replace(regex, "")} onChange={handleChange} />
                                  {errors.length && <div className='text-danger fw-light '>Required </div>}
                                </div>
                                <i className="fa-solid fa-xmark pt-3"></i>
                                <div>
                                  <input className='form-control mx-2' style={{ width: "80px" }} type='text' maxLength='5' name='width' placeholder='width' value={values.width.replace(regex, "")} onChange={handleChange} />
                                  {errors.width && <div className='text-danger fw-light'>Required</div>}
                                </div>
                                <i className="fa-solid fa-xmark pt-3"></i>
                                <div>
                                  <input className='form-control mx-2' style={{ width: "80px" }} type='text' maxLength='5' name='height' placeholder='height' value={values.height.replace(regex, "")} onChange={handleChange} />
                                  {errors.height && <div className='text-danger fw-light'>Required</div>}
                                </div>
                              </div>
                              <div className='d-flex justify-content-end'>
                                <button type='button' className='btn btn-light' onClick={() => closeBoxModal()}>
                                  Close
                                </button>
                                <button type='submit' className='btn btn-primary ms-2'>
                                  Add
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card '>
            <div className="card-header">
              <h4 className="card-header-title">
                Product Details
              </h4>
              <button
                className='btn btn-sm btn-outline-primary'
                onClick={(e) => {
                  handleAddRecord(e);
                }}>
                <i className='fe fe-plus mx-2'></i>
                Add More
              </button>
            </div>
            <table className='table table-sm mb-0' >
              <thead>
                <tr>
                  <th className='text-center'></th>
                  <th>Reference Number</th>
                  <th>
                    Product Type <span className='text-danger'>*</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='list font-size-base'>
                {orderList &&
                  orderList?.map((data, index) => {
                    return (
                      <tr key={`${data}-${index}`}>
                        <td className='text-center'>{`#${index + 1}`}</td>
                        <td >
                          <div className='form-group mb-0'>
                            <input className='form-control' key={index} id='index' type='text' name={`order_number-${index}`} placeholder='e.g. 1112-12322' value={data.order_number} onChange={(e) => handleChangeRecord(index, e, 'orderNumber')} />
                          </div>
                        </td>
                        <td>
                          <div className='form-group  mb-0'>
                            <select className='form-control' key={index} id='index' name={`product_type-${index}`} value={data.product_type} onChange={(e) => handleChangeRecord(index, e, 'productType')}>
                              {productTypes &&
                                productTypes?.map((type, index) => {
                                  return (
                                    <option key={index} value={type.name}>
                                      {type.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </td>
                        <td className='text-center'>
                          {index !== 0 && (
                            <button
                              className='btn btn-sm btn-outline-secondary'
                              onClick={() => {
                                handleRemoveRecord(index);
                              }}>
                              <i className='fe fe-trash'></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="card">
            <div className="card-header">
              <h4 className='header-title'>
                Delivery Address
              </h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className='form-group'>
                  <label className='mb-2'>
                    Recipient Name<span className='text-danger'>*</span>
                  </label>
                  <AsyncCreatableSelect
                    name='contact_name'
                    placeholder='First Name Last Name'
                    type='text'
                    id='name'
                    value={addressData.contact_name ? { label: addressData.contact_name, value: addressData.contact_name } : false}
                    isClearable
                    loadOptions={items}
                    onChange={onChangeFunction}
                  />
                  {!addressData?.contact_name && <div className='text-danger mt-2 '> Required  </div>}
                </div>
              </div>
              <div className="row">
                <div className='form-group'>
                  <label className='mb-2'>Care of / Attn</label>
                  <input className='form-control' type='text' name='secondary_contact_name' placeholder='e.g. William Richard' value={addressData?.secondary_contact_name} onChange={onAddressChange} />
                </div>
              </div>
              <div className="row">
                <div className='form-group'>
                  <label className='mb-2'>Company Name</label>
                  <input className='form-control' type='text' name='company_name' placeholder='e.g. ABC Company' value={addressData?.company_name} onChange={onAddressChange} />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2'>Email</label>
                    <input className='form-control' type='email' name='email' placeholder='e.g. address@example.com' value={addressData?.email} onChange={onAddressChange} />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2'>Phone</label>
                    <div className="form-control">
                      <PhoneInput
                        style={{ border: 'none', outLine: 'none' }}
                        value={addressData.phone}
                        onChange={handleInput}>
                      </PhoneInput>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2'>
                      Address Line 1<span className='text-danger'>*</span>
                    </label>
                    <PlacesAutocomplete
                      name='address1'
                      value={addressData?.address1}
                      onChange={(data) => {
                        handleAddress1Change(data);
                      }}
                      onSelect={handleAddressSelect}>
                      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                        <div className='search-input-container col-12 p-0 mb-4'>
                          <input
                            {...getInputProps({
                              placeholder: 'e.g. 100 W Main St.',
                              className: 'form-control business-form',
                            })}
                          />
                          {!addressData?.address1 && <div className='text-danger mt-2 ms-1 h5'>Address Line 1 is required  </div>}
                          <div className='autocomplete-container bg-light'>
                            {suggestions.map((suggestion, index) => {
                              const className = 'suggestion-item';
                              return (
                                <div key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}>
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2'>Address Line 2</label>
                    <input className='form-control' type='text' name='address2' placeholder='e.g. 105 H Nort St.' value={addressData?.address2} onChange={onAddressChange} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2'>
                      City <span className='text-danger'>*</span>
                    </label>
                    <input type="text" className='form-control' name='city' placeholder='e.g. Seattle' value={addressData?.city} onChange={onAddressChange} />
                    {!addressData?.city && <div className='text-danger mt-2 ms-1 h5'>City  is required  </div>}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2'>State <span className='text-danger'>*</span> </label>
                    <input className='form-control' type='text' name='state' placeholder='e.g. WA' value={addressData?.state} onChange={onAddressChange} />
                    {!addressData?.state && <div className='text-danger mt-2 ms-1 h5'>State is required  </div>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2'>
                      Country Code <span className='text-danger'>*</span>
                    </label>
                    <input className='form-control' type='text' maxLength='2' name='country_code' placeholder='e.g. US' value={'US'} disabled />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label className='mb-2'>
                      Postal Code <span className='text-danger'>*</span>
                    </label>
                    <input className='form-control' type='text' maxLength='5' name='postal_code' placeholder='e.g. 21044' value={addressData?.postal_code} onChange={onAddressChange} />
                    {!addressData?.postal_code && <div className='text-danger mt-2 ms-1 h5'>Postal code is required  </div>}
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='form-group'>
                  <label className='mb-2'>Delivery Instructions</label>
                  <textarea className='form-control' rows='2' name='delivery_instructions' placeholder='e.g. Requires cold storage' value={formData.delivery_instructions} onChange={onInputChange} />
                </div>
              </div>
              <div className='row'>
                <div className='col col-sm-12 col-md-4 mt-3'>
                  <div className='form-check form-switch'>
                    <input className='form-check-input' id='adultSignatureCheckbox' type='checkbox' name='adult_signature' value={formData.adult_signature} checked={formData.adult_signature} onChange={onInputChange} />
                    <label className='form-check-label' htmlFor='adultSignatureCheckbox'>
                      Adult Signature Required
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <button type='button' className={VerifiedAddress ? 'btn btn-light ms-2 ' : 'btn btn-primary ms-2 '} onClick={validateAddressfun} disabled={VerifiedAddress} >
                Validate Address
              </button>
            </div>
          </div>
          <Link className='btn btn-white' to='/shipments'>
            Cancel
          </Link>
          <button type='button' className='btn btn-white ms-2' onClick={getShipmentLabel} disabled={!VerifiedAddress}  >
            <i className="fe fe-eye me-2"></i>
            Preview Shipment Label
          </button>
          <button type='button' className='btn btn-primary ms-2' onClick={confirmShipment} disabled={!VerifiedAddress} >
            Create Shipment
          </button>

        </form>
        <BarcodeReader onError={handleError} onScan={handleScan} />
      </div>
      <Modal show={modalConfirmOrder} onHide={() => showConfirmOrderModal(false)}>
        <Modal.Body>
          <h2 className='text-center text-danger font-weight-bold opacity-75'>Confirm Delivery Request</h2>
          {
            (shipperName === 'MetroHealth Mail Order Pharmacy' || shipperName === 'MetroHealth Specialty Pharmacy') &&
            <div className='text-center font-weight-bold mt-2'>
              <strong>Courier: <span className="text-muted ps-2">U.S. Postal Service(USPS)</span></strong>
            </div>
          }
          <hr />
          <div className='row'>
            <div className='col '>
              <h3 className='font-weight-bold'>Sender</h3>
              <div className='text-nowrap font-weight-bold'>{props.shipper.name}</div>
              <div className='text-muted'>
                <div>{shipperDetails.address?.address1}</div>
                <div>{shipperDetails.address?.address2}</div>
                <div>
                  {shipperDetails.address?.city}, {shipperDetails.address?.state} {shipperDetails.address?.postal_code}`
                </div>
              </div>
            </div>
            <div className='col'>
              <h3 className='font-weight-bold'>Recipient</h3>
              <div className='text-nowrap font-weight-bold'>{addressData.contact_name}</div>
              <div className='text-muted'>
                <div>{addressData?.address1?.trim()}</div>
                <div>{addressData?.address2?.trim()}</div>
                <div>
                  {addressData?.city?.trim()}, {addressData?.state?.trim()} {formData?.postal_code}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <button className='btn btn-light' type='button' onClick={() => showConfirmOrderModal(false)}>
              Back
            </button>
            <button className='btn btn-success ms-2' type='button' onClick={() => onAddNewShipment()}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

    </Spinner>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
    shippers: state.shippers,
    usps: state.select_usps,
  };
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  libraries: ['places', 'visualization'],
})(connect(mapStateToProps)(NewUSPSShipment));


